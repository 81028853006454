import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { remove as removeStudent, update as updateStudent } from '../../services/Students';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '', 
			email: '', 
			ra_bernoulli: '', 
			ra_rnm: '', 
			id_vindi: '', 
			active: 0,
			effective: 0
		}
		this.handleRaBernoulliChange = this.handleRaBernoulliChange.bind(this);
		this.handleRaNotaMilChange = this.handleRaNotaMilChange.bind(this);
		this.handleActiveChange = this.handleActiveChange.bind(this);
		this.handleEffectiveChange = this.handleEffectiveChange.bind(this);
		this.handleName = this.handleName.bind(this);
		this.handleIdVindi = this.handleIdVindi.bind(this);
	}
	handleRaBernoulliChange = event => {
		this.setState({ ra_bernoulli: event.target.value });
	}
	handleRaNotaMilChange = event => {
		this.setState({ ra_rnm: event.target.value });
	}
	handleActiveChange = event => {
		const active = document.getElementById("active").checked;
		this.setState({ active: active ? 1 : 0 });
	}
	handleEffectiveChange = event => {
		const effective = document.getElementById("effective").checked;
		this.setState({ effective: effective ? 1 : 0 });
	}

	handleName = event => {
		this.setState({ name: event.target.value });
	}

	handleIdVindi = event => {
		this.setState({ id_vindi: event.target.value });
	}

	onOpenModal = () => this.setState({ open: true });
	onCloseModal = () => this.setState({ open: false });

	onOpenModalEdit = props => {
		this.setState({ openEdit: true, 
						idEdit: props.id, 
						name: props.name, 
						email: props.email, 
						ra_bernoulli: props.ra_bernoulli, 
						ra_rnm: props.ra_rnm, 
						id_vindi: props.id_vindi,
						active: props.active,
						effective: props.effective,
					});
	}
	onCloseModalEdit = () => this.setState({ openEdit: false });

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeStudent = async props => {
		await removeStudent(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Aluno inativado com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateStudent();
		return false;
	}

	_updateStudent = async () => {
		const data = {
			id: this.state.idEdit,
			name: this.state.name,
			ra_bernoulli: this.state.ra_bernoulli,
			ra_rnm: this.state.ra_rnm, 
			active: this.state.active,
			effective: this.state.effective,
			id_vindi: this.state.id_vindi
		}

		const response = await updateStudent(data);

		if (response.status) {
			this.props.update();
			toast.success("Aluno editado com sucesso!");
		} else {
			toast.success(data);
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];

		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				sortMethod: (a, b) => {
				 if(moment(a.props.children , "DD/MM/YYYY", true).isValid() && 
					 moment(b.props.children , "DD/MM/YYYY", true).isValid()){
					   const aDate = moment(a.props.children, "DD/MM/YYYY");
					   const bDate = moment(b.props.children, "DD/MM/YYYY");

					   if(moment(aDate).isSame(bDate)){
							return moment(aDate).isAfter(bDate) ? 1 : -1;
						}
						return moment(aDate).isAfter(bDate) ? 1 : -1;
					 }else{
						if (a.props.children.localeCompare(b.props.children) === 0) {
						return a.props.children.localeCompare(b.props.children);
					  }
						return a.props.children.localeCompare(b.props.children);
					}
				},
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

		columns.push({
			Header: <b>Ações</b>,
			id: 'delete',
			accessor: str => 'delete',
			Cell: row => {
				return (
					<div>
						<span title="Editar aluno" onClick={() => this.onOpenModalEdit({
							id: row.original.id,
							name: row.original.name,
							email: row.original.email,
							ra_bernoulli: row.original.ra_bernoulli,
							ra_rnm: row.original.ra_rnm,
							id_vindi: row.original.id_vindi, 
							active: row.original.active,
							effective: row.original.efetivo.props.value
						})}>
							<i
								className="fa fa-pencil default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									cursor: 'pointer'
								}}
							></i>
						</span>
						<span onClick={() => {
							this.setState({ rowSelected: row.original.id });
							this.setState({ titleRowSelected: row.original.Nome });
							this.onOpenModal();
						}}
							title="Deletar">
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)
			},
			style: {
				textAlign: 'center',
			},
			sortable: false,
		});

		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja deletar o(a) aluno(a) <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected !== '' ?
								this._removeStudent({ id: this.state.rowSelected }) :
								toast.success("Erro ao deletar o Lead.")}
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Aluno
						</h5>
						<div className="pull-right btnActive">
							<span>{"Ativo"}</span>
							<input 
								type="checkbox" 
								checked={this.state.active} 
								id="active" 
								onChange={this.handleActiveChange}
							/>
							<span style={{marginLeft: 10}}>{"Efetivo"}</span>
							<input
								type="checkbox"
								checked={this.state.effective}
								id="effective"
								onChange={this.handleEffectiveChange}
							/>
						</div>
					</div>
					<form onSubmit={(e) => this._sendForm(e)}>
						<div className="modal-body">
							<div className="form-group">
								<label>Nome</label>
								<input
									type="text"
									className="form-control"
									placeholder="Nome do aluno"
									value={this.state.name}
									onChange={this.handleName}
								/>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Email</label>
										<input
											type="text"
											className="form-control"
											placeholder="Email do aluno"
											value={this.state.email}
											readOnly={true}
										/>
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>ID Vindi</label>
										<input
											type="text"
											className="form-control"
											placeholder="Não cadastrado"
											value={this.state.id_vindi}
											onChange={this.handleIdVindi}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>RA Bernoulli</label>
										<input
											type="text"
											className="form-control"
											id="ra_bernoulli"
											placeholder="Não cadastrado"
											value={this.state.ra_bernoulli}
											onChange={this.handleRaBernoulliChange}
										/>
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>RA Redação Nota 1000</label>
										<input
											type="text"
											className="form-control"
											id="ra_rnm"
											placeholder="Não cadastrado"
											value={this.state.ra_rnm}
											onChange={this.handleRaNotaMilChange}
										/>
									</div>
								</div>
							</div>

						</div>
					</form>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateStudent()}
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
