import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import QuestionTable from './question-table';
import { ToastContainer, toast } from 'react-toastify';
import { getAll, getQuestionFormated, save as saveQuestion } from '../../services/Questions';
import Modal from 'react-responsive-modal';

export class Questions extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allQuestions: '',
		  openEdit: false
		}
	}

	onOpenModalEdit = () => this.setState({ openEdit: true });
	onCloseModalEdit = () => this.setState({ openEdit: false });

	_sendForm = e => {
		e.preventDefault();
		this._saveNew();
		return false;
	}
	_saveNew = async () => {
		const question = document.getElementById("question").value;
		const first = document.getElementById("first").value;
		const second = document.getElementById("second").value;
		const third = document.getElementById("third").value;

		const response = await saveQuestion({
			question: question,
			firstAnswer: first,
			secondAnswer: second,
			thirdAnswer: third
		});

		if (response) {
			toast.success("Pergunta salva com sucesso!");
			this._QuestionsGetAll();
		} else {
			toast.error(response);
		}
			this.onCloseModalEdit();
	}

	_QuestionsGetAll = async () => {
		const allData = await getAll();

		if (allData.error) {
			this.setState({ allLessons: [{ 
				id: 	'0',
				name:	'', 
				Nome: 		<span className='text-danger'>
								<i className="fa fa-meh-o mr-2"></i>
								Nenhuma pergunta encontrada
							</span>,
				Cadastrado: 	'',
				Editado:	'', 
			}] });
		} else {
			const allQuestions = await getQuestionFormated({ data: allData });
			this.setState({ allQuestions });		
		}
	}

	componentDidMount() {
		this._QuestionsGetAll();
	}

    render() {
        return (
            <div>
                <Breadcrumb title="Perguntas" backButton={false} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<div className="btn btn-primary pull-right" onClick={() => this.onOpenModalEdit()}>
										Criar Pergunta
									</div>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <QuestionTable questions={this.state.allQuestions} update={this._QuestionsGetAll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Nova Pergunta
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="form-group" style={{ width: '55vw' }}>
								<label>Pergunta</label>
								<input type="text" className="form-control" id="question" placeholder="Digite aqui a pergunta" />
							</div>
							<div className="form-group">
								<label>Primeira Resposta</label>
								<input type="text" className="form-control" id="first" placeholder="Primeira resposta" />
							</div>
							<div className="form-group">
								<label>Segunda Resposta</label>
								<input type="text" className="form-control" id="second" placeholder="Segunda resposta" />
							</div>
							<div className="form-group">
								<label>Terceira Resposta</label>
								<input type="text" className="form-control" id="third" placeholder="Terceira resposta" />
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._saveNew() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
            </div>
        )
    }
}

export default Questions;
