import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import LessonTable from './lesson-table';
import { ToastContainer, toast } from 'react-toastify';
import { getAll, getLessonFormated, save as saveLesson } from '../../services/Lesson';
import Modal from 'react-responsive-modal';

export class Lesson extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allLessons: '',
		  openEdit: false,
		}
	}

	onOpenModalEdit = () => this.setState({ openEdit: true });
	onCloseModalEdit = () => this.setState({ openEdit: false });

	_sendForm = e => {
		e.preventDefault();
		this._saveNew();
		return false;
	}
	_saveNew = async () => {
		let name = document.getElementById("name").value;
		const response = await saveLesson({ name });

		if (response) {
			toast.success("Disciplina salva com sucesso!");
			this._LessonsGetAll();
		} else {
			toast.error(response);
		}
			this.onCloseModalEdit();
	}

	_LessonsGetAll = async () => {
		const allData = await getAll();

		if (allData.error) {
			this.setState({ allLessons: [{ 
				id: 	'0',
				name:	'', 
				Nome: 		<span className='text-danger'>
								<i className="fa fa-meh-o mr-2"></i>
								Nenhuma Disciplina encontrada
							</span>,
				Cadastrado: 	'',
				Editado:	'', 
			}] });
		} else {
			const allLessons = await getLessonFormated({ data: allData });
			this.setState({ allLessons });		
		}
	}

	componentDidMount() {
		this._LessonsGetAll();
	}

    render() {
        return (
            <div>
                <Breadcrumb title="Disciplinas" backButton={false} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<div className="btn btn-primary pull-right" onClick={() => this.onOpenModalEdit()}>
										Criar Disciplina
									</div>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <LessonTable lessons={this.state.allLessons} update={this._LessonsGetAll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Nova Disciplina
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="form-group">
								<label>Nome da disciplina</label>
								<input type="text" className="form-control" id="name" placeholder="Nome da disciplina" />
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._saveNew() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
            </div>
        )
    }
}

export default Lesson;
