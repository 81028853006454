import React, { Component, Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import { retrieve as retrieveTeacher } from '../services/Teacher';
import { updateStatus } from '../services/Mentoring';

export class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			teacher_id: '', 
			mentoring_status: 'unavalible'
		}
	}

	_getTeacher = async props => {
		this.setState({ teacher_id: props.id });
		const teacher = await retrieveTeacher({ id: props.id });

		if (teacher.mentoring_status!==null) this.setState({ mentoring_status: teacher.mentoring_status });
	}

	_changeType = async () => {
		const teacher_status = document.getElementById("teacher_status").value,
			  updateResponse = await updateStatus({
											id: this.state.teacher_id,
											status: teacher_status
										});
										
		if (updateResponse.status==='success') {
			this.setState({ mentoring_status: teacher_status });
			toast.success("Status atualizado com sucesso!");
		} else { 
			toast.error("Ocorreu um erro ao atualizar o status.");
		}
	}

	componentDidMount() {
		const teacher_id = localStorage.getItem('TEACHER_ID');

		this._getTeacher({ id: teacher_id });
	}
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Dashboard" parent="Dashboard" backButton={false} origin="" />
        
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-9 col-lg-8 col-xl-7">
					<div className="card">
						<div className="card-body">
							<h2 className="text-muted">Status para Mentoria</h2>
							<div className="form-group">
								<select 
									className="form-control mt-1 mt-md-3 mt-lg-4" 
									id="teacher_status"
									value={this.state.mentoring_status}
									onChange={() => this._changeType()}
								>
									<option value='avalible'>Disponível</option>
									<option value='unavalible'>Indisponível</option>
									<option value='busy'>Ocupado</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
		<ToastContainer />
      </Fragment>
    );
  }
}

export default Dashboard;
