import React, {Component, Fragment} from 'react';
import {Tabs, TabList, TabPanel, Tab} from 'react-tabs';
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import { retrieveApi as retrieveBill, update as updateBill } from '../../services/Bills';
import { update as updateCharge } from '../../services/Charges';

var moment = require('moment');

export class Tabset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeShow: true,
			subscription: '',
			plan: '',
			period: '',
			bill: '',
			customer: '',
			faturas: '', 
			editableBills: {}
		}
	}

	clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove('show');
		event.target.classList.add('show');
	}

	_loadEditableBills = () => {
		let editableBills = [];
		if (this.state.faturas.length>0) {
			this.state.faturas.forEach(fatura => {
				if (fatura.status!=='paid') {
					editableBills[fatura.id_vindi] = false;
				}
			});
			this.setState({ editableBills });
		}
	}

	_changeInputState = props => {
		let editableBills = this.state.editableBills;
		editableBills[props.id] = props.status;
		this.setState({ editableBills });
	}

	_saveBill = async props => {
		let editableBills = this.state.editableBills;
		editableBills[props.id] = false;
		this.setState({ editableBills });
		let editValue = document.getElementById("edit_"+props.id).value, 
			updateSuccess=false, 
			charges = [], 
			respondeUpdateBill = false;
		const apiResponse = await retrieveBill({ id: props.id, })

		if (apiResponse.charges!==undefined && apiResponse.charges.length>0) {
			charges = apiResponse.charges;
			charges.forEach(async charge => {
				const responseUpdate = await updateCharge({ id: charge.id, data: { due_at: editValue } });

				if (responseUpdate.success && !updateSuccess) {					
					updateSuccess=true;
					const url = responseUpdate.print_url;
					respondeUpdateBill = await updateBill({ id: props.id, data: { due_at: editValue, url } });
		
					if (respondeUpdateBill) {
						this.props.updateBills();
						toast.success("Vencimento alterado com sucesso!");
					}
				}
			});
		}
	}

	componentDidMount() {
		if (this.props.subscription !== '') this.setState({subscription: this.props.subscription});
		if (this.props.plan !== '') this.setState({plan: this.props.plan});
		if (this.props.period !== '') this.setState({period: this.props.period});
		if (this.props.bill !== '') this.setState({bill: this.props.bill});
		if (this.props.customer !== '') this.setState({customer: this.props.customer});
		if (this.props.faturas !== '') this.setState({faturas: this.props.faturas}, () => {
														this._loadEditableBills();
													});
	}

	componentDidUpdate() {
		if (this.props.subscription !== '' && this.state.subscription === '') this.setState({subscription: this.props.subscription});
		if (this.props.plan !== '' && this.state.plan === '') this.setState({plan: this.props.plan});
		if (this.props.period !== '' && this.state.period === '') this.setState({period: this.props.period});
		if (this.props.bill !== '' && this.state.bill === '') this.setState({bill: this.props.bill});
		if (this.props.customer !== '' && this.state.customer === '') this.setState({customer: this.props.customer});
		if (this.props.faturas !== '' && this.state.faturas !== this.props.faturas) this.setState({faturas: this.props.faturas}, () => {
																						this._loadEditableBills();
																					});
	}

	render() {
		const {subscription, plan, period, bill, customer, faturas} = this.state;
		let charge = '';
		if (bill.charges !== undefined && bill.charges.length !== undefined) charge = bill.charges[bill.charges.length - 1];

		return (
			<Fragment>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab key="tab1" className="nav-link" onClick={(e) => this.clickActive(e)}>Resumo</Tab>
						<Tab key="tab2" className="nav-link" onClick={(e) => this.clickActive(e)}>Assinatura</Tab>
						<Tab key="tab3" className="nav-link" onClick={(e) => this.clickActive(e)}>Usuário</Tab>
						<Tab key="tab4" className="nav-link" onClick={(e) => this.clickActive(e)}>Faturas</Tab>
					</TabList>

					<TabPanel>
						<div key="dvResumo" className="tab-pane fade active show resumoAssinatura">


							<h3>Assinatura</h3>
							<div>
								{plan.name === undefined ?
									<span className="pull-left loading-XL" title="Carregando da API da Vindi">
									<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
								</span> :
									<>
										<i className="fa fa-cubes text-muted mr-2" title="Plano contratado"></i>
										<strong className="text-primary">{plan.name}</strong>
									</>
								}

								{bill.status === undefined ?
									<span className="pull-right loading-XL" title="Carregando da API da Vindi">
											<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
										</span> :
									<>
										{bill.status === 'pending' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-hourglass-half text-danger mr-1"/> Pendente
										</strong>}

										{bill.status === 'review' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-search text-danger mr-1"/> Revisão
										</strong>}

										{bill.status === 'canceled' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-times text-danger mr-1"/> Cancelado
										</strong>}

										{bill.status === 'scheduled' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-clock-o text-danger mr-1"/> Agendado
										</strong>}

										{bill.status === 'paid' &&
										<strong className="text-success pull-right">
											<i className="fa fa-check-square-o text-success mr-1"/> Pago
										</strong>}
									</>}
							</div>
							{(subscription.payment_method_code !== undefined && subscription.payment_method_code.length > 0) &&
							<div className="last">
								{subscription.payment_method_code === 'bank_slip' &&
								<>
									<i className="fa fa-barcode text-muted mr-2" title="Método de pagamento"></i>
									<strong className="text-secondary">Boleto</strong>
								</>
								}
								{subscription.payment_method_code === 'credit_card' &&
								<>
									<i
										className="fa fa-credit-card"
										style={{
											width: 20,
											fontSize: 16
										}}
										title="Método de pagamento"
									></i> <small>Cartão</small>
								</>
								}

								{bill.amount === undefined ?
									<span className="pull-right loading-XL" title="Carregando da API da Vindi">
											<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
										</span> :
									<span className="pull-right"> 
											<strong className="text-primary">R$ {bill.amount.replace(".", ",")}</strong> 
										</span>}
							</div>
							}
							<hr/>

							<h3>Usuário</h3>

							{(subscription.name !== undefined && subscription.name.length > 0) ?
								<div>
									<i className="fa fa-user-circle-o text-muted mr-2" title="Nome do usuário"/>
									<strong className="text-primary">{subscription.name}</strong>
									{subscription.active === 1 ?
										<strong className="text-success pull-right">
											<i className="fa fa-address-card-o text-success mr-1"/> Usuário ativo
										</strong> :
										<strong className="text-danger pull-right">
											<i className="fa fa-address-card-o text-danger mr-1"/> Usuário inativo
										</strong>
									}
								</div> :
								<div> 
								<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
								</span>
								</div>}

							{(subscription.email !== undefined && subscription.email.length > 0) ?
								<div className="last">
									<i className="fa fa-envelope text-muted mr-2" title="Email do usuário"/>
									<strong className="text-primary">{subscription.email}</strong>

									{subscription.verified === 1 ?
										<strong className="text-success pull-right">
											<i className="fa fa-check-square-o text-success mr-1"/> Email verificado
										</strong> :
										<strong className="text-danger pull-right">
											<i className="fa fa-window-close text-danger mr-1"/> Email não verificado
										</strong>
									}
								</div> :
								<div> 
								<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
								</span>
								</div>}
						</div>
					</TabPanel>
					{/* Assinaturas */}
					<TabPanel>
						<div key="dvAssinatura" className="tab-pane fade active show resumoAssinatura">

							<h3>Assinatura</h3>
							<div>
								{plan.name === undefined ?
									<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
									</span> :
									<>
										<i className="fa fa-cubes text-muted mr-2" title="Plano contratado"></i>
										<strong className="text-primary">{plan.name}</strong>
									</>
								}

								{bill.status === undefined ?
									<span className="pull-right loading-XL" title="Carregando da API da Vindi">
												<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
											</span> :
									<>
										{bill.status === 'pending' &&
										<strong className="text-danger pull-right">

											{bill.seen_at !== null ?
												<small
													className="text-muted mr-3">Visto: {moment(bill.seen_at).format("DD/MM/YYYY")}</small> :
												<small className="text-muted mr-3">Não foi visualizado</small>}

											<i className="fa fa-hourglass-half text-danger mr-1"/> Pendente
										</strong>}

										{bill.status === 'review' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-search text-danger mr-1"/> Revisão
										</strong>}

										{bill.status === 'canceled' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-times text-danger mr-1"/> Cancelado
										</strong>}

										{bill.status === 'scheduled' &&
										<strong className="text-danger pull-right">
											<i className="fa fa-clock-o text-danger mr-1"/> Agendado
										</strong>}

										{bill.status === 'paid' &&
										<strong className="text-success pull-right">
											<i className="fa fa-check-square-o text-success mr-1"/> Pago
										</strong>}
									</>}
							</div>
							{(subscription.payment_method_code !== undefined && subscription.payment_method_code.length > 0) &&
							<div>
								{subscription.payment_method_code === 'bank_slip' &&
								<>
									<i className="fa fa-barcode text-muted mr-2" title="Método de pagamento"></i>
									<strong className="text-secondary">
										Boleto
										{(charge.payment_method !== undefined && charge.payment_method.name !== undefined)
										&& <small className="text-muted ml-3">({charge.payment_method.name})</small>}
									</strong>
								</>
								}
								{subscription.payment_method_code === 'credit_card' &&
								<>
									<i
										className="fa fa-credit-card"
										style={{
											width: 20,
											fontSize: 16
										}}
										title="Método de pagamento"
									></i> <small>Cartão</small>
								</>
								}

								{bill.amount === undefined ?
									<span className="pull-right loading-XL" title="Carregando da API da Vindi">
												<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
											</span> :
									<span className="pull-right"> 
												<strong
													className="text-primary">R$ {bill.amount.replace(".", ",")}</strong> 
											</span>}
							</div>
							}
							<div className="datas">
								{subscription.created_at === undefined ?
									<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
									</span> :
									<>
										<small className="text-muted">Cadastrado: </small>
										<strong
											className="text-primary">{moment(subscription.created_at).format("DD/MM/YYYY")}</strong>
										<span className="pull-right">
												{subscription.updated_at !== undefined &&
												<>
													<small className="text-muted">Atualizado: </small>
													<strong
														className="text-primary">{moment(subscription.updated_at).format("DD/MM/YYYY")}</strong>
												</>}
											</span>
									</>
								}
							</div>
							<div className="datas">
								{subscription.start_at === undefined ?
									<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
									</span> :
									<>
										<small className="text-muted">Início: </small>
										<strong
											className="text-primary">{moment(subscription.start_at).format("DD/MM/YYYY")}</strong>
										<span className="pull-right">
												{subscription.end_at !== undefined &&
												<>
													<small className="text-muted">Término: </small>
													<strong
														className="text-primary">{moment(subscription.end_at).format("DD/MM/YYYY")}</strong>
												</>}
											</span>
									</>
								}
							</div>

							{period.billing_at !== null &&
							<div className="datas">
								<>
									<small className="text-muted">Fatura atual: </small>
									<strong
										className="text-primary">{moment(period.billing_at).format("DD/MM/YYYY")}</strong>
								</>
								{subscription.next_billing_at === undefined ?
									<span className="pull-left loading-XL" title="Carregando da API da Vindi">
											<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
										</span> :
									<span className="pull-right">												
												<small className="text-muted">Próxima fatura: </small>
												<strong
													className="text-primary">{moment(subscription.next_billing_at).format("DD/MM/YYYY")}</strong>
											</span>
								}
							</div>
							}
							{subscription.cancel_at !== null &&
							<div className="datas">
								<>
									<small className="text-muted">Cancelado: </small>
									<strong
										className="text-primary">{moment(subscription.cancel_at).format("DD/MM/YYYY")}</strong>
								</>
							</div>
							}
							{bill.due_at !== null &&
							<div className="datas">
								<>
									<small className="text-muted">Vencimento: </small>
									<strong className="text-primary">{moment(bill.due_at).format("DD/MM/YYYY")}</strong>
								</>
								{charge.paid_at !== null ?
									<span className="pull-right">
												<small className="text-muted">Pago: </small>
												<strong
													className="text-primary">{moment(charge.paid_at).format("DD/MM/YYYY")}</strong>
											</span> :
									<span className="pull-right">
												<small className="text-muted">Pago: </small>
												<strong className="text-danger">NÃO</strong>
											</span>
								}
							</div>
							}
							{charge.print_url !== undefined ?
								<div className="last">
									<a href={charge.print_url} target="_blank" rel="noopener noreferrer">
										<strong className="text-primary">Link do Boleto</strong>
									</a>
								</div> :
								<div className="last">
									<strong className="text-danger">
										Boleto não encontrado
									</strong>
								</div>
							}
						</div>
					</TabPanel>
					{/* Usuários */}
					<TabPanel>
						<div key="dvUsuario" className="tab-pane fade active show resumoAssinatura">

							<h3>Usuário</h3>

							{(subscription.name !== undefined && subscription.name.length > 0) ?
								<div>
									<i className="fa fa-user-circle-o text-muted mr-2" title="Nome do usuário"/>
									<strong className="text-primary">{subscription.name}</strong>
									{subscription.active === 1 ?
										<strong className="text-success pull-right">
											<i className="fa fa-address-card-o text-success mr-1"/> Usuário ativo
										</strong> :
										<strong className="text-danger pull-right">
											<i className="fa fa-address-card-o text-danger mr-1"/> Usuário inativo
										</strong>
									}
								</div> :
								<div> 
								<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
								</span>
								</div>}

							{(subscription.email !== undefined && subscription.email.length > 0) ?
								<div
									className={(customer.registry_code === undefined || customer.registry_code.length === 0) && "last"}>
									<i className="fa fa-envelope text-muted mr-2" title="Email do usuário"/>
									<strong className="text-primary">{subscription.email}</strong>

									{subscription.verified === 1 ?
										<strong className="text-success pull-right">
											<i className="fa fa-check-square-o text-success mr-1"/> Email verificado
										</strong> :
										<strong className="text-danger pull-right">
											<i className="fa fa-window-close text-danger mr-1"/> Email não verificado
										</strong>
									}
								</div> :
								<div
									className={(customer.registry_code === undefined || customer.registry_code.length === 0) && "last"}> 
								<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
								</span>
								</div>}

							{(customer.registry_code !== undefined && customer.registry_code.length > 0) ?
								<div className="last">
									<small className="text-muted">CPF: </small>
									<strong className="text-primary">
										{customer.registry_code.substring(0, 3)}
										.{customer.registry_code.substring(3, 6)}
										.{customer.registry_code.substring(6, 9)}
										-{customer.registry_code.substring(9, 11)}
									</strong>
								</div> :
								<div className="last"> 
								<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
								</span>
								</div>}

							<hr/>
							<h3>Endereço</h3>
							{customer.address === undefined ?
								<div className="last">
									<span className="pull-left loading-XL" title="Carregando da API da Vindi">
										<ReactLoading type={'bars'} color={"#27286f"} height={20} width={40}/>
									</span>
								</div> :
								<>
									{customer.address.street &&
									<div>
										<i className="fa fa-map-marker text-muted mr-2" title="Endereço"/>
										<strong className="text-primary">
											{customer.address.street}, &nbsp;
											{customer.address.number !== null && customer.address.number} /
											{customer.address.additional_details !== null && customer.address.additional_details} -
											CEP: {customer.address.zipcode !== null && customer.address.zipcode}
										</strong>
									</div>}

									{customer.address.neighborhood &&
									<div className="last">
										<small className="text-muted">Bairro: </small>
										<strong className="text-primary">
											{customer.address.neighborhood}
										</strong>
										<span className="pull-right">
											{customer.address.city !== null &&
											<>
												<strong className="text-primary">
													{customer.address.city}
												</strong>
												<small className="text-muted"> - </small>
											</>}

											{customer.address.state !== null &&
											<>
												<strong className="text-primary">
													{customer.address.state}
												</strong>
												<small className="text-muted"> - </small>
											</>}

											{customer.address.country !== null &&
											<>
												<strong className="text-primary">
													{customer.address.country}
												</strong>
											</>}
										</span>
									</div>}
								</>
							}
						</div>
					</TabPanel>
					<TabPanel>
						<div key="dvFaturas" className="tab-pane fade active show faturasAssinatura">
							<h3>Faturas</h3>

							{faturas.length > 0 &&
							<div className="row headerTable">
								<div className="col">
									<small className="text-muted">Referencia</small>
								</div>
								<div className="col">
									<small className="text-muted">Status</small>
								</div>
								<div className="col">
									<small className="text-muted">Valor</small>
								</div>
								<div className="col">
									<small className="text-muted">Boleto</small>
								</div>
								<div className="col">
									<small className="text-muted">Ciclo</small>
								</div>
								<div className="col">
									<small className="text-muted">Data de vencimento</small>
								</div>
								<div className="col">
									<small className="text-muted">Editar Vencimento</small>
								</div>
							</div>}

							{faturas.length > 0 ? this.state.faturas.map(item => {
								let classRow = "text-danger"; 
								if (item.status==='paid') {
									classRow = "text-success";
								} else if (item.status==='pending') {
									classRow = "text-info";
								}
								let payment_method_code = '';
								if (item.url!=='') {
									payment_method_code = 'bank_slip';
								} else {
									payment_method_code = 'credit_card';
								}

								return (
									<div className="row bodyTable" key={item.id}>
										<div className="col">
											<strong  style={{marginRight: 20}} className={classRow}>{item.id_vindi}</strong>
										</div>
										<div className="col">
											<strong  style={{marginRight: 20}} className={classRow}>
												{item.status==='paid' && 'Pago'}
												{item.status==='pending' && 'Pendente'}
												{item.status==='waiting' && 'Aguardando Pagamento'}
												{(item.status!=='paid' && item.status!=='pending' && item.status!=='waiting') && item.status}
											</strong>
										</div>
										<div className="col">
											<strong className={classRow}>R$ {item.amount}</strong>
										</div>
										<div className="col">
											<strong className={classRow}>
												<a href={item.url} target="_blank">Link do boleto</a>
											</strong>
										</div>
										<div className="col">
											<strong className={classRow}>{item.period_cycle}</strong>
										</div>
										<div className="col">
											<strong className={classRow} style={{ display: !this.state.editableBills[item.id_vindi] ? 'block' : 'none' }}>
												{moment(item.due_at).format("DD/MM/YYYY")}
											</strong>
											{item.status!=='paid' && 
												<input type="date" defaultValue={moment(item.due_at).format("YYYY-MM-DD")} id={'edit_'+item.id_vindi} style={{ display: this.state.editableBills[item.id_vindi] ? 'block' : 'none' }} /> 
											}
										</div>
										<div className="col">
											{item.status!=='paid' ? 
												<>
													<div className="btn btn-sm btn-primary" style={{ display: !this.state.editableBills[item.id_vindi] ? 'inline-block' : 'none' }} title={"Editar vencimento da fatura " + item.id_vindi} onClick={()=> this._changeInputState({ id: item.id_vindi, status: true }) }>
														<i className="fa fa-pencil"></i>
													</div>
													<div className="btn btn-sm btn-success" style={{ display: this.state.editableBills[item.id_vindi] ? 'inline-block' : 'none' }} title={"Salvar fatura " + item.id_vindi} onClick={()=> this._saveBill({ id: item.id_vindi, customer_id: item.customer_id, payment_method_code: payment_method_code  }) }>
														<i className="fa fa-save"></i>
													</div>
												</>
											: 
												<div className="btn btn-sm btn-outline-secondary" title={"Edição desabilitada. Esta fatura está paga."} disabled>
													<i className="fa fa-ban text-light-text"></i>
												</div>
											}
										</div>
									</div>)
							}) :
							<div className="row headerTable pt-2">
								<div className="col-12 text-center">
									<h3 className="text-muted">Nenhuma fatura encontrada</h3>
								</div>
							</div> }
						</div>
					</TabPanel>
				</Tabs>
				{/* <div className="pull-right">
                    <button type="button" className="btn btn-primary">Save</button>
                </div> */}
				<ToastContainer />
			</Fragment>
		)
	}
}

export default Tabset
