import React, { Component } from "react";
import { getToken, retrieveUser } from '../../../services/Auth';

export class User_panel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {}
		}
	}
	_getUser = async props => {
		const user = await retrieveUser({ token: props.token });		
		this.setState({ user });
	}
	componentDidMount() {
		const token = getToken();
		this._getUser({ token });
	}
  render() {
    return (
      <div>
        <div className="sidebar-user text-center">
          <h6 className="mt-2 f-10">
			  {this.state.user.user!==undefined ?
			  	<>
				  <div className="row">
					  <div className="col-3 pr-0 pt-2 text-right">
					  	<i className="fa fa-user-circle-o mr-2 fa-2x" aria-hidden="true"></i>
					  </div>
					  <div className="col-9 pl-0 text-left">
					  	{this.state.user.user.name}
					  </div>
				  </div>
			  	</> : 'Carregando' }
		  </h6>
        </div>
      </div>
    );
  }
}

export default User_panel;
