import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { remove as removeTeacher, update as updateTeacher } from '../../services/Teacher';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			thumb: '', 
			thumb_type: '', 
			thumb_name: '',
			labelImage: 'Escolher imagem', 
		}
		this.handleNameChange = this.handleNameChange.bind(this);
		this.fileInput = React.createRef();
	}
	handleNameChange(event) {
		this.setState({name: event.target.value});
	}

	clearState = () => {
		this.setState({
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			thumb: '', 
			thumb_type: '', 
			thumb_name: '',
			labelImage: 'Escolher imagem', 
		});
	}

	onOpenModal = () => this.setState({ open: true });
	onCloseModal = () => {
		this.clearState();
	};

	onOpenModalEdit = props => {
		this.setState({ 
						openEdit: true, 
						idEdit: props.id, 
						name: props.name,
						labelImage: props.thumb!==null ? <img src={props.thumb} alt="thumb da aula" className="img_thumb" /> : null,  
					});
	}
	onCloseModalEdit = () => {
		this.clearState();
	};

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeTeacher = async props => {
		await removeTeacher(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Professor removido com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateTeacher();
		return false;
	}

	_updateTeacher = async () => {
		let data = {
			id: this.state.idEdit,
			name: this.state.name
		}
		if (this.state.thumb!=='' && this.state.thumb_type!=='') {
			data = {
				...data,
				thumbnail: this.state.thumb,
				thumb_type: this.state.thumb_type,
				file_name: this.state.thumb_name
			}
		}
		const response = await updateTeacher(data);
		if (response.status) {
			this.props.update();
			toast.success("Professor editado com sucesso!");
		} else {
			toast.success(response);
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	_changeImage = () => {
		if (this.fileInput.current.files.length>0) {
			const file = this.fileInput.current.files[0];
			var reader  = new FileReader();
			const type = file.type,
				  size = file.size,
				  fileName = file.name;
			reader.onloadend = () => {
				if (size<251658240) {					
					this.setState({ 
									thumb: reader.result, 
									thumb_type: type, 
									thumb_name: fileName ,
									labelImage: <img src={reader.result} alt="thumbnail" className="img_thumb" />
								})
				} else {
					this.setState({ labelImage: "Escolher imagem" })
					toast.error("A imagem enviada é muito grande. Ela deve ter no máximo 1 MB.");
				}
			}

			if (file) {
				reader.readAsDataURL(file);
			} else {
				toast.error("Erro ao pegar o conteúdo da imagem.");
			}			
		} else { 
			toast.error("Erro ao pegar o conteúdo da imagem.");
		}
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];

		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

			columns.push({
				Header: <b>Ações</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => {
					return (
					<div>
						<span title="Editar professor" onClick={() => this.onOpenModalEdit({ 
																								id: row.original.id, 
																								name: row.original.name, 
																								thumb: row.original.thumb 
																							})}>
							<i
								className="fa fa-pencil default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
								}}
							></i>
						</span>
						<span onClick={() => {
							this.setState({ rowSelected: row.original.id });
							this.setState({ titleRowSelected: row.original.Nome });
							this.onOpenModal();
						}}
						title="Deletar">
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)},
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});

		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja remover o professor <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected!=='' ? 
											this._removeTeacher({ id: this.state.rowSelected }) :
											toast.success("Erro ao deletar o professor.") }
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Professor
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="form-group">
								<label>Nome do professor</label>
								<input 
									type="text" 
									className="form-control" 
									id="name" 
									placeholder="Nome do professor" 
									value={this.state.name} 
									onChange={this.handleNameChange}
								/>
							</div>
							<div className="custom-file mt-1">
								<input 
									type="file" 
									className="fileInput" 
									ref={this.fileInput}
									id="file" 
									onChange={() => this._changeImage()} 
								/>
								<label className="custom-file-label" for="file">{this.state.labelImage}</label>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateTeacher() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
