import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import ContentTable from './content-table';
import { ToastContainer, toast } from 'react-toastify';
import { getAll, getContentFormated, save as saveContent } from '../../services/Contents';
import Modal from 'react-responsive-modal';
import ReactLoading from 'react-loading';

export class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allContent: '',
		  openEdit: false,
		  labelImage: `Escolher arquivo (PDF/imagem)`,
		  thumb: '',
		  thumb_type: '',
		  thumb_name: '', 
		  loading: false,
		}
		this.fileInput = React.createRef();
	}
	
	clearState = () => {
		this.setState({
			allContent: '',
			openEdit: false,
			labelImage: `Escolher arquivo (PDF/imagem)`,
			thumb: '',
			thumb_type: '',
			thumb_name: '', 
			loading: false
		});
	}

	onOpenModalEdit = () => this.setState({ openEdit: true });
	onCloseModalEdit = () => {
		this.clearState();
	}

	_changeImage = () => {
		if (this.fileInput.current.files.length>0) {
			const file = this.fileInput.current.files[0];
			var reader  = new FileReader();
			const type = file.type,
				  size = file.size,
				  fileName = file.name;
			reader.onloadend = () => {
				if (size<251658240) {
					this.setState({ 
									thumb: reader.result, 
									thumb_type: type, 
									thumb_name: fileName ,
									labelImage: "Documento carregado."
								})
				} else {
					this.setState({ labelImage: "Escolher imagem" })
					toast.error("A imagem enviada é muito grande. Ela deve ter no máximo 1 MB.");
				}
			}

			if (file) {
				reader.readAsDataURL(file);
			} else {
				toast.error("Erro ao pegar o conteúdo da imagem.");
			}			
		} else { 
			toast.error("Erro ao pegar o conteúdo da imagem.");
		}
	}
	_sendForm = e => {
		e.preventDefault();
		this._saveNew();
		return false;
	}
	_saveNew = async () => {
		let name = document.getElementById("name").value,
			author = document.getElementById("author").value,
			year = document.getElementById("year").value;
		this.setState({loading: true})
		document.getElementById('salvar').textContent = "Aguarde..."
		document.getElementById('salvar').setAttribute("disabled","disabled");
		const response = await saveContent({ 
												name,
												author, 
												year, 
												thumbnail: this.state.thumb!=='' ? this.state.thumb : null,
												thumb_type: this.state.thumb_type!=='' ? this.state.thumb_type : null,
												file_name: this.state.thumb_name!=='' ? this.state.thumb_name : null 
											});
		if (response && !response.error) {
			this.setState({loading: false, thumb: '', thumb_type: '', thumb_name: ''})
			toast.success("Material salvo com sucesso!");
			this._ContentsGetAll();
		} else {
			document.getElementById('salvar').textContent = "Salvar"
			document.getElementById('salvar').setAttribute("enabled","enabled");
			this.setState({loading: false, thumb: '', thumb_type: '', thumb_name: ''})
			toast.error(response.error.message);
		}
		this.onCloseModalEdit();
	}

	_ContentsGetAll = async () => {
		const allData = await getAll();
		if (allData.error) {
			this.setState({ allContent: [{ 
				id: 	'0',
				title:	'',
				thumb: 	'', 
				Nome: 		<span className='text-danger'>
								<i className="fa fa-meh-o mr-2"></i>
								Nenhum Material encontrado
							</span>,
				Professor: 	'',
				Data:	'', 
			}] });
		} else {
			const allContent = await getContentFormated({ data: allData });
			this.setState({ allContent });
		}
	}

	componentDidMount() {
		this._ContentsGetAll();
	}

    render() {
        return (
            <div>
                <Breadcrumb title="Materiais" backButton={false} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<div className="btn btn-primary pull-right" onClick={() => this.onOpenModalEdit()}>
										Criar Material
									</div>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <ContentTable lessons={this.state.allContent} update={this._ContentsGetAll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Novo Material
						</h5>
					</div>
					<form onSubmit={(e) => this._sendForm(e) }>
						<div className="modal-body">
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Nome do material</label>
										<input type="text" className="form-control" id="name" placeholder="Nome do material" />
									</div>
									<div className="form-group">
										<label>Autor</label>
										<input type="text" className="form-control" id="author" placeholder="Autor do material" />
									</div>
									<div className="form-group">
										<label>Ano de publicação</label>
										<input type="number" className="form-control" id="year" placeholder="Ano do material" />
									</div>
								</div>
								<div className="col-12 col-sm-6 pt-3">
									<div className="custom-file mt-2">
										<input 
											type="file" 
											className="fileInput" 
											ref={this.fileInput}
											id="file" 
											onChange={() => this._changeImage()} 
										/>
										<label className="custom-file-label" for="file">{this.state.labelImage}</label>
									</div>	
								</div>
							</div>
						</div>
					</form>
					{this.state.loading && (
						<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100}/>
					)}
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							id="salvar"
							type="button"
							className="btn btn-secondary"
							onClick={() => this._saveNew() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
            </div>
        )
    }
}

export default Content;
