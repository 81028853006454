import React, { Component, Fragment } from 'react';
import Datatable from '../common/datatableStudents';
import ReactLoading from 'react-loading';

export class StudentTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allStudents: ''
		}
	}
	componentDidMount() {
		if (this.props.students !== '') {
			this.setState({ allStudents: this.props.students });
		}
	}
	componentDidUpdate() {
		if (this.props.students !== '' && (this.state.allstudents === '' || this.props.students !== this.state.allStudents)) {
			this.setState({ allStudents: this.props.students });
		}
	}
	render() {
		return (
			<Fragment>
				<div className="translation-list studentsTable">
					{this.state.allStudents.length > 0 ?
						<>
							{this.state.allStudents[0].id !== '0' ?
								<Datatable
									myData={this.state.allStudents}
									pageSize={this.state.allStudents.length <= 10 ? this.state.allStudents.length : 10}
									pagination={true}
									className="-striped -highlight"
									update={() => this.props.update()}
								/> :
								<h3>{this.state.allStudents[0].Nome}</h3>
							}
						</> :
						<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />
					}
				</div>
			</Fragment>
		)
	}
}

export default StudentTable;
