import React, { Component, Fragment } from 'react';
import Datatable from '../common/datatableQuestion';
import ReactLoading from 'react-loading';

export class QuestionTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allQuestions: ''
		}
	}
	componentDidMount() {
		if (this.props.questions!=='') {
			this.setState({ allQuestions: this.props.questions });
		}
	}
	componentDidUpdate() {
		if (this.props.questions!=='' && (this.state.allQuestions==='' || this.props.questions!==this.state.allQuestions)) {
			this.setState({ allQuestions: this.props.questions });
		}
	}
    render() {
        return (
            <Fragment>
                <div className="translation-list questionsTable">
				{this.state.allQuestions.length>0 ?
					<>
					{this.state.allQuestions[0].id!=='0' ?
                    <Datatable
                        myData={this.state.allQuestions}
                        pageSize={this.state.allQuestions.length <= 10 ? this.state.allQuestions.length : 10}
                        pagination={true}
                        className="-striped -highlight"
						update={() => this.props.update()}
                    />:
					<h3>{this.state.allQuestions[0].Nome}</h3>
					}
					</>:
					<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />
					}
                </div>
            </Fragment>
        )
    }
}

export default QuestionTable;
