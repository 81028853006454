import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import LeadsTable from './newsletter-table';
import { ToastContainer, toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import { getAll, getNewsFormated } from '../../services/Newsletter';
var moment = require('moment');

export class Newsletter extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allNewsletter: ''
		}
	}
	_NewsletterGetAll = async () => {
		const newsFormated = await getNewsFormated({ data: await getAll() });
		this.setState({ allNewsletter: newsFormated });		
	}

	_exportCSV = props => {
		if (this.state.allNewsletter.length===0) return toast.error("Nenhum lead para exportar.")
		const leadsFormated = [];
		this.state.allNewsletter.forEach(lead => { 
			if (lead.email!==null) leadsFormated.push({ email: lead.Email });
		});
		const options = { 
			filename: "csv-WRatHOME-"+moment().format("DD-MM-YYYY"), 
			fieldSeparator: ",",
			quoteStrings: '"',
			title: "CSV dos Leads do WRatHOME", 
			showLabels: false, 
			useTextFile: props.type==='csv' ? false : true,
			useBom: true,
			useKeysAsHeaders: false,
			// headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
		};
		if (leadsFormated.length>0) {
			const csvExporter = new ExportToCsv(options);
			return csvExporter.generateCsv(leadsFormated);
		} 
		return toast.error("Nenhum lead para exportar.")
	}

	componentDidMount() {
		this._NewsletterGetAll();
	}

    render() {
        return (
            <div>
                <Breadcrumb title="Newsletter" parent="Leads" backButton={false} origin="" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>
										Leads
										<span 
											className="pull-right default-color cursor-pointer" onClick={()=>this._exportCSV({ type: 'texto' })}
											style={{ fontSize: 12 }}
										>
											<i
												className="fa fa-file-text-o"
												style={{
													width: 20,
													fontSize: 20,
													padding: 5,
													color: '#27286f',
													cursor: 'pointer',
												}}
												title="Exportar o arquivo TXT"
											></i> Texto
										</span>
										<span 
											className="pull-right default-color cursor-pointer" onClick={()=>this._exportCSV({ type: 'csv' })}
											style={{ fontSize: 12, marginRight: 10 }}
										>
											<i
												className="fa fa-file-code-o"
												style={{
													width: 20,
													fontSize: 20,
													padding: 5,
													color: '#27286f',
													cursor: 'pointer',
												}}
												title="Exportar o arquivo CSV"
											></i> CSV
										</span>
									</h5>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <LeadsTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<ToastContainer />
            </div>
        )
    }
}

export default Newsletter;
