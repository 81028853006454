import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { remove as removeQuestion, update as updateQuestion } from '../../services/Questions';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			question: '',
			first_answer: '',
			second_answer: '',
			third_answer: ''
		}
		this.handleQuestionChange = this.handleQuestionChange.bind(this);
		this.handleFirstAnswerChange = this.handleFirstAnswerChange.bind(this);
		this.handleSecondAnswerChange = this.handleSecondAnswerChange.bind(this);
		this.handleThirdAnswerChange = this.handleThirdAnswerChange.bind(this);
	}
	handleQuestionChange = event => {
		this.setState({ question: event.target.value });
	}
	handleFirstAnswerChange = event => {
		this.setState({ first_answer: event.target.value });
	}
	handleSecondAnswerChange = event => {
		this.setState({ second_answer: event.target.value });
	}
	handleThirdAnswerChange = event => {
		this.setState({ third_answer: event.target.value });
	}

	onOpenModal = () => this.setState({ open: true });
	onCloseModal = () => this.setState({ open: false });

	onOpenModalEdit = props => {
		this.setState({ 
							openEdit: true, 
							idEdit: props.id, 
							question: props.question, 
							first_answer: props.first_answer!==null ? props.first_answer : '', 
							second_answer: props.second_answer!==null ? props.second_answer : '', 
							third_answer: props.third_answer!==null ? props.third_answer : '' 
						});
	}
	onCloseModalEdit = () => this.setState({ openEdit: false });

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeQuestion = async props => {
		await removeQuestion(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Pergunta removida com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateQuestion();
		return false;
	}

	_updateQuestion = async () => {
		const data = {
			id: this.state.idEdit,
			question: this.state.question,
			first_answer: this.state.first_answer,
			second_answer: this.state.second_answer,
			third_answer: this.state.third_answer
		}
		const response = await updateQuestion(data);

		if (response.status) {
			this.props.update();
			toast.success("Pergunta editada com sucesso!");
		} else {
			toast.success(response);
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];

		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

			columns.push({
				Header: <b>Ações</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => {
					return (
					<div>
						<span title="Editar pergunta" onClick={() => this.onOpenModalEdit({ 
																								id: row.original.id, 
																								question: row.original.question, 
																								first_answer: row.original.first_answer, 
																								second_answer: row.original.second_answer, 
																								third_answer: row.original.third_answer
																							})}>
							<i
								className="fa fa-pencil default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
								}}
							></i>
						</span>
						<span onClick={() => {
							this.setState({ rowSelected: row.original.id });
							this.setState({ titleRowSelected: row.original.Pergunta });
							this.onOpenModal();
						}}
						title="Deletar">
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)},
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});

		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja remover a pergunta <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected!=='' ? 
											this._removeQuestion({ id: this.state.rowSelected }) :
											toast.success("Erro ao deletar o Lead.") }
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Pergunta
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="form-group" style={{ width: '55vw' }}>
								<label>Pergunta</label>
								<input 
									type="text" 
									className="form-control" 
									id="name" 
									placeholder="Pergunta" 
									value={this.state.question} 
									onChange={this.handleQuestionChange}
								/>
							</div>
							<div className="form-group">
								<label>Primeira Resposta</label>
								<input 
									type="text" 
									className="form-control" 
									id="first" 
									placeholder="Primeira resposta" 
									value={this.state.first_answer} 
									onChange={this.handleFirstAnswerChange}
								/>
							</div>
							<div className="form-group">
								<label>Segunda Resposta</label>
								<input 
									type="text" 
									className="form-control" 
									id="second" 
									placeholder="Segunda resposta" 
									value={this.state.second_answer} 
									onChange={this.handleSecondAnswerChange}
								/>
							</div>
							<div className="form-group">
								<label>Terceira Resposta</label>
								<input 
									type="text" 
									className="form-control" 
									id="third" 
									placeholder="Terceira resposta" 
									value={this.state.third_answer} 
									onChange={this.handleThirdAnswerChange}
								/>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateQuestion() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
