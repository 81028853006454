import api from './api';
const axios = require('axios');

export const getAll = async () => {
const response = await axios.get( process.env.REACT_APP_API_URL + '/transmition' )
	.then(response => response.data)
	.catch(error => error )
	return response;
}

export const save = async props => {
	const response = await axios.post( process.env.REACT_APP_API_URL + '/transmition', { 
																							name: props.name, 
																							lesson_id: props.lesson_id, 
																							teacher_id: props.teacher_id, 
																							transmition_id: props.transmition_id, 
																							data: props.data, 
																							start: props.start, 
																							finish: props.finish
																						})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const update = async props => {
	const response = await axios.put( process.env.REACT_APP_API_URL + '/transmition', { 
																							id:props.id, 
																							name: props.name, 
																							lesson_id: props.lesson_id, 
																							teacher_id: props.teacher_id, 
																							transmition_id: props.transmition_id, 
																							data: props.data, 
																							start: props.start, 
																							finish: props.finish
																						})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const remove = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/transmition/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const CreateChannelRequest = async event => {
	return new Promise(async function (resolve, reject) {
		api.post(`/channel/create/`, event, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(response => {
				resolve(response.data);
			})
			.catch(_error => {
				resolve(false);
			});
	});
};

export const CreateLiveRequest = async live => {
	return new Promise(async function (resolve, reject) {
		const liveData = live;
		const channel_id = liveData.channel_id;
		delete liveData.channel_id;
		api.post(
			`/transmission/create-transmission/`,
			{ id: channel_id, transmissionBody: liveData },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			},
		)
			.then(response => {
				resolve(response.data);
			})
			.catch(_error => {
				resolve(false);
			});
	});
};
