import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { 
			remove as removeContent, 
			update as updateContent, 
			retrieve as retrieveContent, 
			getAllClassContent, 
			addClassContent, 
			removeClassContent, 
			getAllLessonsContent, 
			addLessonContent, 
			removeLessonContent, 
			SelectLessons, 
			SelectClasses, 
} from '../../services/Contents';
import { getAll as getAllClasses } from '../../services/Classes';
import { getAll as getAllLessons } from '../../services/Lesson';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			author: '', 
			year: '', 
			vol: '', 
			frente: '', 
			thumb: '', 
			thumb_type: '', 
			thumb_name: '',
			labelImage: 'Escolher imagem', 
			lessons: '', 
			classes: '', 
			selectedLessons: [], 
			selectedClasses: []
		}
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleAuthorChange = this.handleAuthorChange.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
		this.handleVolumeChange = this.handleVolumeChange.bind(this);
		this.handleFrenteChange = this.handleFrenteChange.bind(this);
		this.fileInput = React.createRef();
	}
	handleNameChange(event) {
		this.setState({name: event.target.value});
	}
	handleAuthorChange(event) {
		this.setState({ author: event.target.value});
	}
	handleYearChange(event) {
		this.setState({ year: event.target.value});
	}
	handleVolumeChange(event) {
		this.setState({ vol: event.target.value});
	}
	handleFrenteChange(event) {
		this.setState({ frente: event.target.value});
	}

	clearState = () => {
		this.setState({
			open: false,
			openEdit: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			author: '', 
			year: '', 
			vol: '', 
			frente: '', 
			thumb: '', 
			thumb_type: '', 
			thumb_name: '',
			labelImage: 'Escolher imagem',
		})
	}

	onOpenModal = () => this.setState({ open: true });
	onCloseModal = () => { 
		this.clearState();
	};

	_removeClassContent = async props => {
		const response = await removeClassContent({ id: props.id });
		if (response.success) {
			this._getAllClassContent({ id: this.state.idEdit });
			toast.success(response.message);
		}
	}
	_removeLessonContent = async props => {
		const response = await removeLessonContent({ id: props.id });
		if (response.success) {
			this._getAllLessonsContent({ id: this.state.idEdit });
			toast.success(response.message);
		}
	}

	_getAllLessonsContent = async props => {
		const lessonContent = await getAllLessonsContent({ id: props.id });

		if (lessonContent.error) return this.setState({ selectedLessons: [] });

		if (lessonContent.error) return false;
		const selectedLessons = lessonContent.map(content => {
				  return <div key={content.id}>
							{content.lesson}
							<div className="pull-right">
								<i className="fa fa-trash-o text-danger cursorPointer" aria-hidden="true" onClick={() => this._removeLessonContent({ id: content.id })}></i>
							</div>
						</div>
			  });
		this.setState({ selectedLessons })
	}

	_getAllClassContent = async props => {
		const classContent = await getAllClassContent({ id: props.id });

		if (classContent.error) return this.setState({ selectedClasses: [] });

		if (classContent.error) return false;
		const selectedClasses = classContent.map(content => {
				  return <div key={content.id}>
							{content.class}
							<div className="pull-right">
								<i className="fa fa-trash-o text-danger cursorPointer" aria-hidden="true" onClick={() => this._removeClassContent({ id: content.id })}></i>
							</div>
						</div>
			  });
		this.setState({ selectedClasses })
	}

	onOpenModalEdit = async props => {
		const content = await retrieveContent({ id: props.id });
		this._getAllClassContent({ id: props.id });
		this._getAllLessonsContent({ id: props.id });
		this.setState({ 
						openEdit: true, 
						idEdit: props.id, 
						name: props.name,
						author: props.author,
						year: content.year,
						vol: content.vol,
						frente: content.frente,
						labelImage: props.thumb!==null ? <img src={props.thumb} alt="thumb da aula" className="img_thumb" /> : null,  
					});
	}
	onCloseModalEdit = () => {
		this.clearState();
	};

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeContent = async props => {
		await removeContent(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Material removido com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateContent();
		return false;
	}

	_updateContent = async () => {
		let data = {
			id: this.state.idEdit,
			name: this.state.name,
			author: this.state.author,
			year: this.state.year,
			vol: this.state.vol,
			frente: this.state.frente
		}
		if (this.state.thumb!=='' && this.state.thumb_type!=='') {
			data = {
				...data,
				thumbnail: this.state.thumb,
				thumb_type: this.state.thumb_type,
				file_name: this.state.thumb_name
			}
		}
		const response = await updateContent(data);
		if (response.status) {
			this.props.update();
			toast.success("Material editado com sucesso!");
		} else {
			toast.success(response);
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	_changeImage = () => {
		if (this.fileInput.current.files.length>0) {
			const file = this.fileInput.current.files[0];
			var reader  = new FileReader();
			const type = file.type,
				  size = file.size,
				  fileName = file.name;
			reader.onloadend = () => {
				if (size<251658240) {					
					this.setState({ 
									thumb: reader.result, 
									thumb_type: type, 
									thumb_name: fileName ,
									labelImage: <img src={reader.result} alt="thumbnail" className="img_thumb" />
								})
				} else {
					this.setState({ labelImage: "Escolher imagem" })
					toast.error("A imagem enviada é muito grande. Ela deve ter no máximo 1 MB.");
				}
			}

			if (file) {
				reader.readAsDataURL(file);
			} else {
				toast.error("Erro ao pegar o conteúdo da imagem.");
			}			
		} else { 
			toast.error("Erro ao pegar o conteúdo da imagem.");
		}
	}

	_ClassesGetAll = async () => {
		const allData = await getAllClasses(); 

		if (allData.error) {
			this.setState({ classes: [{ 
				id: 	'0',
				value: 	'Nenhuma Aula cadastrada'
			}] });
		} else {
			if (allData.length>0) {
				const allClasses = allData.map((classInfo) => {
					return { 
						id: 	classInfo.id,
						value:	classInfo.title
					}
				});
				this.setState({ classes: allClasses });
			}
		}
	}

	_LessonsGetAll = async () => {
		const lessons = await getAllLessons();

		if (lessons.error) {
			this.setState({ lessons: [{ 
				id: 	'0',
				value: 	'Nenhuma Disciplina cadastrada'
			}] });
		} else {
			
			if (lessons.length>0) {
				const allLessons = lessons.map((lesson) => {
					return { 
						id: 	lesson.id,
						value:	lesson.name
					}
				});
				this.setState({ lessons: allLessons });
			}
		}
	}

	_addLesson = async () => {
		const lesson_id = document.getElementById("lesson_id").value,
			  content_id = this.state.idEdit;

		if (lesson_id!=='') {
			const response = await addLessonContent({ lesson_id, content_id });

			if (response.success) {
				this._getAllLessonsContent({ id: content_id });
				toast.success(response.message);
				document.getElementById("class_id").value = '';
			} else { 
				toast.error("Erro ao adicionar a Aula");
			}
		} else { 
			toast.error("Selecione uma Aula antes de adicionar");
		}
	}

	_addClass = async () => {
		const class_id = document.getElementById("class_id").value,
			  content_id = this.state.idEdit;

		if (class_id!=='') {
			const response = await addClassContent({ class_id, content_id });

			if (response.success) {
				this._getAllClassContent({ id: content_id });
				toast.success(response.message);
				document.getElementById("class_id").value = '';
			} else { 
				toast.error("Erro ao adicionar a Aula");
			}
		} else { 
			toast.error("Selecione uma Aula antes de adicionar");
		}
	}

	componentDidMount() {
		this._ClassesGetAll();
		this._LessonsGetAll();
	}
	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];

		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

			columns.push({
				Header: <b>Ações</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => {
					return (
					<div>
						<span title="Editar material" onClick={() => this.onOpenModalEdit({ 
																								id: row.original.id, 
																								name: row.original.name,
																								author: row.original.author,
																								thumb: row.original.thumb 
																							})}>
							<i
								className="fa fa-pencil default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
								}}
							></i>
						</span>
						<span onClick={() => {
							this.setState({ rowSelected: row.original.id });
							this.setState({ titleRowSelected: row.original.name });
							this.onOpenModal();
						}}
						title="Deletar">
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)},
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});

		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja remover o material <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected!=='' ? 
											this._removeContent({ id: this.state.rowSelected }) :
											toast.success("Erro ao deletar o material.") }
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Material
						</h5>
					</div>
					<form onSubmit={(e) => this._sendForm(e) }>
						<div className="modal-body">
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Nome do material</label>
										<input 
											type="text" 
											className="form-control" 
											id="name" 
											placeholder="Nome do material" 
											value={this.state.name} 
											onChange={this.handleNameChange}
										/>
									</div>
									<div className="row">
										<div className="col-12 col-sm-6">											
											<div className="form-group">
												<label>Autor</label>
												<input 
													type="text" 
													className="form-control" 
													id="author" 
													placeholder="Autor do material" 
													value={this.state.author} 
													onChange={this.handleAuthorChange}
												/>
											</div>
										</div>
										<div className="col-12 col-sm-6">											
											<div className="form-group">
												<label>Ano</label>
												<input 
													type="number" 
													className="form-control" 
													id="year" 
													placeholder="Ano do material" 
													value={this.state.year} 
													onChange={this.handleYearChange}
												/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-12 col-sm-6">											
											<div className="form-group">
												<label>Volume</label>
												<input 
													type="number" 
													className="form-control" 
													id="vol" 
													placeholder="Volume" 
													value={this.state.vol} 
													onChange={this.handleVolumeChange}
												/>
											</div>
										</div>
										<div className="col-12 col-sm-6">											
											<div className="form-group">
												<label>Frente</label>
												<input 
													type="text" 
													maxLength='1'
													className="form-control" 
													id="frente" 
													placeholder="Frente" 
													value={this.state.frente} 
													onChange={this.handleFrenteChange}
												/>
											</div>
										</div>
									</div>

									<label>Imagem</label>
									<div className="custom-file">
										<input 
											type="file" 
											className="fileInput" 
											ref={this.fileInput}
											id="file" 
											onChange={() => this._changeImage()} 
										/>
										<label className="custom-file-label" for="file">{this.state.labelImage}</label>
									</div>
								</div>
								<div className="col-12 col-sm-6">
									
									<label>Adicionar Disciplina relacionada</label>
									<div className="input-group mb-3">
										<SelectLessons lessons={this.state.lessons} />
  										<div className="input-group-append">
											<button 
												className="btn btn-outline-secondary" 
												type="button"
												onClick={this._addLesson}
											>
												<i className="fa fa-plus" aria-hidden="true"></i>
											</button>
										</div>
									</div>
									
									<label>Adicionar Aula relacionada</label>
									<div className="input-group">
										<SelectClasses lessons={this.state.classes} /> 
  										<div className="input-group-append">
											<button 
												className="btn btn-outline-secondary" 
												type="button"
												onClick={this._addClass}
											>
												<i className="fa fa-plus" aria-hidden="true"></i>
											</button>
										</div>
									</div>
									<div style={{ display: this.state.selectedLessons.length>0 ? 'block' : 'none' }} className="mt-3">
										<label>Disciplinas Relacionadas</label>
										<div className="card cardSelectedClasses">
											<div className="card-body">
												{this.state.selectedLessons}
											</div>
										</div>
									</div>
									<div style={{ display: this.state.selectedClasses.length>0 ? 'block' : 'none' }}>
										<label>Aulas Relacionadas</label>
										<div className="card cardSelectedClasses">
											<div className="card-body">
												{this.state.selectedClasses}
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</form>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateContent() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
