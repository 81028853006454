import React from 'react';
import { getToken } from './Auth';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
	const token = getToken();	
	
	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
	}

const response = await axios.get( process.env.REACT_APP_API_URL + '/user' )
	.then(response => response.data)
	.catch(error => { return { error: error} })
	return response;
}

export const getUserFormated = async props => {
	let userFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		userFormated = props.data.map((user) => {
			if (user.deleted_at) className='text-danger'
			else className='text-success';					
			return { 
				id: user.id,
				name: 		user.name,
				email: 		user.email,
				permission: user.permission,
				teacher_id: user.teacher_id,
				Nome: 		<span className={className}>{user.name}</span>,
				Login: 		<span className={className}>{user.email}</span>,
				Permissão: <span className={className}>
								{user.permission==='2' && 'Professor'}
								{user.permission==='3' && 'Admin'}
							</span>,
				Editado: 	<span className={className}>
								{moment(user.updated_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")}
							</span>
			}
		});
	}
	return userFormated;
}

export const save = async props => {
	const response = await axios.post( process.env.REACT_APP_API_URL + '/user', { 
																					name: props.name, 
																					email: props.login, 
																					password: props.password, 
																					is_admin: props.is_admin,
																					teacher_id: props.teacher_id
																				})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const update = async props => {	
	const response = await axios.put( process.env.REACT_APP_API_URL + '/user', { 
																					id:props.id, 
																					name: props.name, 
																					email: props.email, 
																					password: props.password, 
																					is_admin: props.is_admin, 
																					teacher_id: props.teacher_id
																				})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const remove = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/user/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const SelectTeachers = props => {
	let teachers = [];
	if (props.teachers.length>0) teachers = props.teachers.map(teacher => <option key={teacher.id} value={teacher.id}>{teacher.name}</option>)

	return <select 
				className="form-control" 
				id="teacher_id" 
				defaultValue={props.id}
				onChange={() => {
					if (props.update) props.update(document.getElementById("teacher_id").value) 
				}}
			>	
				{teachers.length>0 ?
				<>
					<option value='' disabled>Selecione um professor</option>
					{teachers}
				</> :
					<option value='0' disabled>Nenhum professor cadastrado</option> 
				}
		   	</select>;
}
