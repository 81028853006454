import React from 'react';
import { getToken } from './Auth';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
	const token = getToken();
	if (token !== '') {
		axios.defaults.headers.common['Authorization'] = token;
	}
	const response = await axios
		.get(process.env.REACT_APP_API_URL + '/student')
		.then(response => response.data)
		.catch(error => {
			return { error: error };
		});
	return response;
};

export const getAllFiltered = async props => {
	const token = getToken();
	if (token !== '') {
		axios.defaults.headers.common['Authorization'] = token;
	}
	const response = await axios
		.get(process.env.REACT_APP_API_URL + `/student/list/${props}`)
		.then(response => response.data)
		.catch(error => {
			return { error: error };
		});

	return response;
};

export const getStudentFormated = async props => {
	let lessonFormated = [],
		className = 'text-muted';
	if (props.data.length > 0) {
		lessonFormated = props.data.map(student => {
			if (student.active === 1) className = 'text-success';
			else className = 'text-danger';

			return {
				id: student.id,
				name: student.name,
				email: student.email,
				ra_bernoulli: student.ra_bernoulli,
				ra_rnm: student.ra_rnm,
				id_vindi: student.id_vindi,
				active: student.active,
				Nome: <span className={className}>{student.name}</span>,
				Email: <span className={className}>{student.email}</span>,
				efetivo: <span className={className} value={student.effective}>{student.effective ? 'Sim' : 'Não'}</span>,
				Cadastrado: <span className={className}>
					{moment(student.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")}
				</span>,
				Editado: <span className={className}>
					{moment(student.updated_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")}
				</span>
			}
		});
	}
	return lessonFormated;
};

export const update = async props => {
	const token = getToken();
	if (token !== '') {
		axios.defaults.headers.common['Authorization'] = token;
	}
	const response = await axios.put(process.env.REACT_APP_API_URL + '/student/' + props.id, {
		active: props.active,
		effective: props.effective,
		ra_bernoulli: props.ra_bernoulli,
		ra_rnm: props.ra_rnm,
		name: props.name, 
		id_vindi: props.id_vindi
	})
		.then(response => response.data)
		.catch(error => error);
	return response;
};

export const remove = async props => {
	const token = getToken();
	if (token !== '') {
		axios.defaults.headers.common['Authorization'] = token;
	}
	const response = await axios
		.delete(process.env.REACT_APP_API_URL + '/student/' + props.id)
		.then(response => response.data)
		.catch(error => error);
	return response;
};
