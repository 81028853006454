import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { login } from '../../services/Auth';

export class LoginTabset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeShow: true,
			startDate: new Date(),
		};
		this.handleChange = this.handleChange.bind(this);
	}

	clickActive = event => {
		document.querySelector('.nav-link').classList.remove('show');
		event.target.classList.add('show');
	};
	handleChange(date) {
		this.setState({
			startDate: date,
		});
	}

	_getPermission = () => localStorage.getItem('PERMISSION');

	verifyLogin = async e => {
		e.preventDefault();				
		let email = document.getElementById("email").value,
			password = document.getElementById("password").value, 
			goTo = `/teacher-dashboard`;
	
		const permission = await login({ email, password });
		
		if (permission==='3') goTo = `/dashboard`;

		if (!permission) {
			toast.error("Login inválido");
		} else {
			this.props.history.push(goTo);
		}
	}
	render() {
		return (
			<div>
				<Fragment>
					<Tabs>
						<TabList className="nav nav-tabs tab-coupon">
							<Tab
								className="nav-link"
								onClick={e => this.clickActive(e)}
							>
								<User />
								Login
							</Tab>
						</TabList>

						<TabPanel>
							<form className="form-horizontal auth-form" id="formLogin" onSubmit={(e) => this.verifyLogin(e) }>
								<div className="form-group">
									<input
										required
										name="email"
										type="email"
										className="form-control"
										placeholder="Email"
										id="email"
										autoComplete="on"
									/>
								</div>
								<div className="form-group">
									<input
										required
										name="password"
										type="password"
										className="form-control"
										placeholder="Password"
										id="password"
										autoComplete="off"
									/>
								</div>
								<div className="form-button">
									<button
										className="btn btn-primary"
										type="submit"
									>
										Login
									</button>
								</div>
							</form>
						</TabPanel>
					</Tabs>
				</Fragment>
				<ToastContainer />
			</div>
		);
	}
}

export default withRouter(LoginTabset);
