import React, { Component, Fragment } from 'react';
import { User } from 'react-feather';
import { logout } from '../../../services/Auth';

export class UserMenu extends Component {
	render() {
		return (
			<Fragment>
				<li className="onhover-dropdown">
					<div className="media align-items-center">
						<User />
					</div>
					<ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
						<li>
							<div onClick={logout}>
								<i data-feather="log-out"></i>Sair
							</div>
						</li>
					</ul>
				</li>
			</Fragment>
		);
	}
}

export default UserMenu;
