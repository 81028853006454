import React, { Component, Fragment } from 'react';
import Datatable from '../common/datatableLesson';
import ReactLoading from 'react-loading';

export class LessonTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allLessons: ''
		}
	}
	componentDidMount() {
		if (this.props.lessons!=='') {
			this.setState({ allLessons: this.props.lessons });
		}
	}
	componentDidUpdate() {
		if (this.props.lessons!=='' && (this.state.allLessons==='' || this.props.lessons!==this.state.allLessons)) {
			this.setState({ allLessons: this.props.lessons });
		}
	}
    render() {
        return (
            <Fragment>
                <div className="translation-list lessonsTable">
				{this.state.allLessons.length>0 ?
					<>
					{this.state.allLessons[0].id!=='0' ?
                    <Datatable
                        myData={this.state.allLessons}
                        pageSize={this.state.allLessons.length <= 10 ? this.state.allLessons.length : 10}
                        pagination={true}
                        className="-striped -highlight"
						update={() => this.props.update()}
                    />:
					<h3>{this.state.allLessons[0].Nome}</h3>
					}
					</>:
					<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />
					}
                </div>
            </Fragment>
        )
    }
}

export default LessonTable;
