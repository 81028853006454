import { getToken } from './Auth';
const axios = require('axios');

export const getAll = async (id) => {
	const token = getToken();	

	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/question/presence/${id}`)
			.then(response => response.data)
			.catch(error => { return { error: error.response.data.error} })
			return response;
	} else {
		return { error: {message: "não foi possível verificar o token", code: 403} }
	}
}
