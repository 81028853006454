import React, { Component, Fragment } from 'react';
import Datatable from '../common/datatableClass';
import ReactLoading from 'react-loading';

export class ClassTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allClasses: '',
		  lessons: '', 
		  teachers: '', 
		  transmitions: ''
		}
	}
	componentDidMount() {
		if (this.props.allClasses!=='') this.setState({ allClasses: this.props.allClasses });
		if (this.props.lessons!=='') this.setState({ lessons: this.props.lessons });
		if (this.props.teachers!=='') this.setState({ teachers: this.props.teachers });
		if (this.props.transmitions!=='') this.setState({ transmitions: this.props.transmitions });
	}
	componentDidUpdate() {
		if (this.props.allClasses!=='' && (this.state.allClasses==='' || this.props.allClasses!==this.state.allClasses)) {
			this.setState({ allClasses: this.props.allClasses });
		}

		if (this.props.lessons!=='' && (this.state.lessons==='' || this.props.lessons!==this.state.lessons)) { 
			this.setState({ lessons: this.props.lessons });
		}
		if (this.props.teachers!=='' && (this.state.teachers==='' || this.props.teachers!==this.state.teachers)) { 
			this.setState({ teachers: this.props.teachers });
		}
		if (this.props.transmitions!=='' && (this.state.transmitions==='' || this.props.transmitions!==this.state.transmitions)) { 
			this.setState({ transmitions: this.props.transmitions });
		}
	}
    render() {
        return (
            <Fragment>
                <div className="translation-list classTable">
				{this.state.allClasses.length>0 ? 
					<>
					{this.state.allClasses[0].id!=='0' ?
                    <Datatable
                        myData={this.state.allClasses}
                        pageSize={this.state.allClasses.length <= 10 ? this.state.allClasses.length : 10}
                        pagination={true}
                        className="-striped -highlight"
						update={() => this.props.update()}
						lessons={this.state.lessons} 
						teachers={this.state.teachers} 
						transmitions={this.state.transmitions}
                    />:
					<h3>{this.state.allClasses[0].Nome}</h3>
					}
					</>:
					<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />
					}
                </div>
            </Fragment>
        )
    }
}

export default ClassTable;
