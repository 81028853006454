import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset';
import { retrieve, retrieveApi } from '../../services/Subscriptions';
import { retrieveApi as retrievePlanApi } from '../../services/Plans';
import { retrieveApi as retrievePeriodApi } from '../../services/Periods';
import { retrieveApi as retrieveBillApi, retrieveApiBills } from '../../services/Bills';
import { retrieveApi as retrieveCustomerApi } from '../../services/Customers';

// export function DetailsSubscription(props) {
export class DetailsSubscription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subscriptionDetails: '',
			subscriptionApiDetails: '',
			planDetails: '',
			periodDetails: '',
			billDetails: '',
			customerDetails: '',
			faturas: ''
		}
	}
	_SubscriptionRetrieve = async props => {
		const subscriptionDetails = await retrieve({ id: props.id });
		this.setState({ subscriptionDetails });

		if (subscriptionDetails.customer_id!==undefined) {			
			const customerDetails = await retrieveCustomerApi({ id: subscriptionDetails.customer_id });
			this.setState({ customerDetails });
		}

		if (subscriptionDetails.plan_id!==undefined) {
			const planDetails = await retrievePlanApi({ id: subscriptionDetails.plan_id });
			this.setState({ planDetails });
		}

		if (subscriptionDetails.sub_gateway_id!==undefined) {
			const subscriptionApiDetails = await retrieveApi({ id: subscriptionDetails.sub_gateway_id });
			this.setState({ subscriptionApiDetails });

			if (subscriptionApiDetails.current_period!==undefined) {
				const currentPeriod = subscriptionApiDetails.current_period;

				if (currentPeriod.id!==undefined) {
					const periodDetails = await retrievePeriodApi({ id: currentPeriod.id });
					this.setState({ periodDetails });

					if (periodDetails.usages!==undefined && periodDetails.usages.length>0) {
						const lastUsage = periodDetails.usages[periodDetails.usages.length-1];

						if (lastUsage.bill!==undefined) {
							const lastBill = lastUsage.bill;

							if (lastBill!==null) {
								if (lastBill.id!==undefined) {
									const billDetails = await retrieveBillApi({ id: lastBill.id });
									this.setState({ billDetails });
								}
							}
						}
					}
				}
			}
		}

		if (subscriptionDetails){
			const faturas = await retrieveApiBills({id: this.state.subscriptionApiDetails.id})
			this.setState({faturas});

			
		}
	}
	_updateBills = async () => {


		if (this.state.subscriptionApiDetails!=='' && this.state.subscriptionApiDetails.id!==''){
			const faturas = await retrieveApiBills({id: this.state.subscriptionApiDetails.id})
			this.setState({faturas});

		
		}
	}

	componentDidMount() {
		if (this.props.match.params.id!=='') this._SubscriptionRetrieve({ id: this.props.match.params.id });
	}
    render() {
		return (
			<Fragment>
				<Breadcrumb 
					title="Detalhes da Assinatura" 
					parent={<><small>ID:</small> <strong>{this.props.match.params.id}</strong></>} 
					backButton={true}
					origin="/subscriptions"
				/>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-body">
									<Tabset 
										subscription={this.state.subscriptionDetails} 
										plan={this.state.planDetails}
										period={this.state.periodDetails}
										bill={this.state.billDetails}
										customer={this.state.customerDetails}
										faturas={this.state.faturas}
										updateBills={this._updateBills}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default DetailsSubscription;
