import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { remove as removeLesson, update as updateLesson } from '../../services/Lesson';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: ''
		}
		this.handleNameChange = this.handleNameChange.bind(this);
	}
	handleNameChange(event) {
		this.setState({name: event.target.value});
	}

	onOpenModal = () => this.setState({ open: true });
	onCloseModal = () => this.setState({ open: false });

	onOpenModalEdit = props => {
		this.setState({ openEdit: true, idEdit: props.id, name: props.name });
	}
	onCloseModalEdit = () => this.setState({ openEdit: false });

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeLesson = async props => {
		await removeLesson(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Disciplina removida com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateLesson();
		return false;
	}

	_updateLesson = async () => {
		const data = {
			id: this.state.idEdit,
			name: this.state.name
		}
		const response = await updateLesson(data);

		if (response.status) {
			this.props.update();
			toast.success("Disciplina editada com sucesso!");
		} else {
			toast.success(data);
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];

		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

			columns.push({
				Header: <b>Ações</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => {
					return (
					<div>
						<span title="Editar disciplina" onClick={() => this.onOpenModalEdit({ 
																								id: row.original.id, 
																								name: row.original.name 
																							})}>
							<i
								className="fa fa-pencil default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
								}}
							></i>
						</span>
						<span onClick={() => {
							this.setState({ rowSelected: row.original.id });
							this.setState({ titleRowSelected: row.original.Nome });
							this.onOpenModal();
						}}
						title="Deletar">
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)},
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});

		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja remover a disciplina <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected!=='' ? 
											this._removeLesson({ id: this.state.rowSelected }) :
											toast.success("Erro ao deletar o Lead.") }
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Disciplina
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="form-group">
								<label>Nome da disciplina</label>
								<input 
									type="text" 
									className="form-control" 
									id="name" 
									placeholder="Nome da disciplina" 
									value={this.state.name} 
									onChange={this.handleNameChange}
								/>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateLesson() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
