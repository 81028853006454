import React from 'react';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
const response = await axios.get( process.env.REACT_APP_API_URL + '/lesson' )
	.then(response => response.data)
	.catch(error => { return { error: error} })
	return response;
}

export const getLessonFormated = async props => {
	let lessonFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		lessonFormated = props.data.map((lesson) => {
			if (lesson.deleted_at) className='text-danger'
			else className='text-success'

			return { 
				id: lesson.id,
				name: 		lesson.name,
				Nome: 		<span className={className}>{lesson.name}</span>,
				Cadastrado:	<span className={className}>
								{moment(lesson.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>, 
				Editado: 	<span className={className}>
								{moment(lesson.updated_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>
			}
		});
	}
	return lessonFormated;
}

export const save = async props => {
	const response = await axios.post( process.env.REACT_APP_API_URL + '/lesson', { name: props.name })
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const update = async props => {
	const response = await axios.put( process.env.REACT_APP_API_URL + '/lesson', { id:props.id, name: props.name })
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const remove = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/lesson/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}
