import React from 'react';
import { getToken } from './Auth';
const axios = require('axios');
var moment = require('moment');

export const getTeacherDiscussions = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/discussion/' + props.id )
	.then(response => response.data)
	.catch(error => { return { error: error} })
	return response;
}

export const getTeacherDiscussionsFormated = async props => {
	let classesFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		classesFormated = props.data.map((classInfo) => {

			if (classInfo.deleted_at || classInfo.active===0) className='text-danger'
			else className='text-success';			
			return { 
				id: 	classInfo.id,
				title:	classInfo.title,
				thumb: 	classInfo.thumbnail, 
				Nome: 		<span className={className}>{classInfo.title}</span>,
				Professor: 	<span className={className}>{classInfo.teacher}</span>,
				Data:	<span className={className}>
								{moment(classInfo.date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>, 
			}
		});
	}
	return classesFormated;
}

export const getTeacherClassesFormated = async props => {
	let classesFormated = [],
		className='text-muted';

	if (props.data.length>0) {
		classesFormated = props.data.map((classInfo) => {

			if (classInfo.deleted_at || classInfo.active===0) className='text-danger'
			else className='text-success';			
			return { 
				id: 	classInfo.id,
				title:	classInfo.title,
				thumb: 	classInfo.thumbnail, 
				Nome: 		<span className={className}>{classInfo.title}</span>,
				Data:	<span className={className}>
								{moment(classInfo.date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>, 
				Início: 	<span className={className}>{classInfo.start_at}</span>,
				Término: 	<span className={className}>{classInfo.finish_at}</span>,
			}
		});
	}
	return classesFormated;
}

export const save = async props => {
	const token = getToken(),
		  id = props.id, 
		  text = props.text;	
	
	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
	}
	const response = await axios.post( process.env.REACT_APP_API_URL + '/discussion/answer', { 
																					id,
																					text
																				})
								.then(response => response.data)
								.catch(error => error )

	console.log(">> Axios > response: ", response);

	return response;
}

export const update = async props => {
	const response = await axios.put( process.env.REACT_APP_API_URL + '/class/'+props.id, { 
																					name: props.name,
																					lesson_id: props.lesson_id, 
																					teacher_id: props.teacher_id, 
																					transmition_id: props.transmition_id, 
																					date: props.date, 
																					start_at: props.start_at, 
																					finish_at: props.finish_at,
																					thumbnail: props.thumbnail,
																					thumb_type: props.thumb_type,
																					file_name: props.file_name 
																				})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const retrieve = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/class/show/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const remove = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/class/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const SelectLessons = props => {
	let lessons = [];
	if (props.lessons.length>0) lessons = props.lessons.map(lesson => <option key={lesson.id} value={lesson.id}>{lesson.name}</option>)

	return <select 
				className="form-control" 
				id="lesson_id" 
				defaultValue={props.id}
				onChange={() => {
					if (props.update) props.update(document.getElementById("lesson_id").value) 
				}}
			>
			{lessons.length>0 ?
			<>
				<option value=''>Selecione uma disciplina</option>
				{lessons}
			</> :
				<option value='0' disabled>Nenhum disciplina cadastrada</option> 
			}
		   	</select>;
}
