import React from 'react';
import { Link } from 'react-router-dom';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
	const response = await axios
		.get(process.env.REACT_APP_API_URL + '/subscription')
		.then(response => response.data)
		.catch(error => error);
	return response;
};

export const getAllFiltered = async props => {
	const response = await axios
		.get(process.env.REACT_APP_API_URL + `/subscription/filter/${props}`)
		.then(response => response.data)
		.catch(error => {
			return { error: error };
		});
	return response;
};

export const getSubscriptionsFormated = async props => {
	let subscriptionsFormated = [],
		className = 'text-muted';
	if (props.data.length > 0) {
		subscriptionsFormated = props.data.map(subscription => {
			if (subscription.deleted_at) {
				className = 'text-danger';
			} else if (subscription.active === 1) className = 'text-success';
			else if (subscription.active === 0) className = 'text-danger';
			return {
				id: subscription.subscription_id,
				Nome: (
					<Link
						to={`/subscription/${subscription.subscription_id}`}
						title="Detalhes"
					>
						<span className={className}>{subscription.name}</span>
					</Link>
				),
				Email: (
					<Link
						to={`/subscription/${subscription.subscription_id}`}
						title="Detalhes"
					>
						<span className={className}>{subscription.email}</span>
					</Link>
				),
				Método: (
					<Link
						to={`/subscription/${subscription.subscription_id}`}
						title="Detalhes"
					>
						<span className={className}>
							{subscription.payment_method_code ===
								'bank_slip' && (
								<>
									<i
										className="fa fa-barcode"
										style={{
											width: 20,
											fontSize: 16,
										}}
									></i>{' '}
									<small>Boleto</small>
								</>
							)}
							{subscription.payment_method_code ===
								'credit_card' && (
								<>
									<i
										className="fa fa-credit-card"
										style={{
											width: 20,
											fontSize: 16,
										}}
									></i>{' '}
									<small>Cartão</small>
								</>
							)}
						</span>
					</Link>
				),
				Início: (
					<Link
						to={`/subscription/${subscription.subscription_id}`}
						title="Detalhes"
					>
						<small className={className}>
							{moment(
								subscription.start_at,
								'YYYY-MM-DD hh:mm:ss',
							).format('DD/MM/YYYY')}
						</small>
					</Link>
				),
				Término: (
					<Link
						to={`/subscription/${subscription.subscription_id}`}
						title="Detalhes"
					>
						<small className={className}>
							{moment(
								subscription.end_at,
								'YYYY-MM-DD hh:mm:ss',
							).format('DD/MM/YYYY')}
						</small>
					</Link>
				),
				Cadastrado: (
					<Link
						to={`/subscription/${subscription.subscription_id}`}
						title="Detalhes"
					>
						<small className={className}>
							{moment(
								subscription.created_at,
								'YYYY-MM-DD hh:mm:ss',
							).format('DD/MM/YYYY')}
						</small>
					</Link>
				),
				status:
					subscription.deleted_at === null ? 'active' : 'inactive',
			};
		});
	}
	return subscriptionsFormated;
};

export const retrieve = async props => {
	const response = await axios
		.get(process.env.REACT_APP_API_URL + '/subscription/' + props.id)
		.then(response => response.data)
		.catch(error => error);
	return response;
};

export const retrieveApi = async props => {
	const response = await axios
		.get(
			process.env.REACT_APP_API_URL + '/subscription/gateway/' + props.id,
		)
		.then(response => response.data)
		.catch(error => error);
	return response;
};

export const remove = async props => {
	const response = await axios
		.delete(process.env.REACT_APP_API_URL + '/subscription/' + props.id)
		.then(response => response.data)
		.catch(error => error);
	return response;
};
