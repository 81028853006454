import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import StudentTable from './students-table';
import {
	getAllFiltered,
	getAll,
	getStudentFormated,
} from '../../services/Students';

export class Students extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allStudents: '',
		};
	}

	_StudentsGetAll = async () => {
		const allData = await getAll();

		if (allData.error) {
			this.setState({
				allStudents: [
					{
						id: '0',
						name: '',
						Nome: (
							<span className="text-danger">
								<i className="fa fa-meh-o mr-2"></i>
								Nenhum aluno(a) encontrado(a)
							</span>
						),
						Cadastrado: '',
						Editado: '',
					},
				],
			});
		} else {
			const allStudents = await getStudentFormated({ data: allData });
			this.setState({ allStudents });
		}
	};

	_StudentsGetAllFiltered = async text => {
		const allData = await getAllFiltered(text);

		if (allData.error) {
			this.setState({
				allStudents: [
					{
						id: '0',
						name: '',
						Nome: (
							<span className="text-danger">
								<i className="fa fa-meh-o mr-2"></i>
								Nenhum aluno(a) encontrado(a)
							</span>
						),
						Cadastrado: '',
						Editado: '',
					},
				],
			});
		} else {
			const allStudents = await getStudentFormated({ data: allData });
			this.setState({ allStudents });
		}
	};

	_listFilter = async () => {
		const searchText = document.getElementById('searchText').value;
		if(searchText){
			this._StudentsGetAllFiltered(searchText);
		}
	};

	componentDidMount() {
		this._StudentsGetAll();
	}

	render() {
		return (
			<div>
				<Breadcrumb title="Alunos" backButton={false} />
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<div className="input-group mb-3">
										<input
											type="text"
											id="searchText"
											className="form-control"
											placeholder="Filtrar por nome ou email"
											aria-label="Filtrar lista"
											aria-describedby="btnFiltrar"
											onChange={e => {
												if(e.target.value.length >= 3){
													this._listFilter()
												}else if(e.target.value.length === 0){
													this._StudentsGetAll()
												}
											}}
										/>
										<div className="input-group-append">
											<button
												className="btn btn-outline-secondary"
												type="button"
												id="btnFiltrar"
												onClick={() =>
													this._listFilter()
												}
											>
												Filtrar
											</button>
										</div>
									</div>
									<div
										id="basicScenario"
										className="report-table"
									>
										<StudentTable
											students={this.state.allStudents}
											update={this._StudentsGetAll}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Students;
