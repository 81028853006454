import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Chart } from "react-google-charts";
import ReactLoading from 'react-loading';
const SurveysService = require('../../services/Surveys');

export class Surveys extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  allSurveys: '', 
		  pieItems: []
		}
	}

	_SurveysGetAll = async () => {
		const allSurveys = await SurveysService.getAll();
		this.setState({ allSurveys });
	}
	_starVotes = nota => SurveysService.starVotes(nota);
	_loadItems = props => {
		const response = SurveysService.loadItems({ surveys: this.state.allSurveys, colors: props.colors, });
		if (this.state.pieItems.length===0 && response.pieItems) this.setState({ pieItems: response.pieItems });
		if (response.items.length>0) return response.items;
	}
	componentDidMount() {
		this._SurveysGetAll();
	}
    render() {
        return (
            <div>
                <Breadcrumb title="Votação" parent="Votos" backButton={false} origin="" />
                <div className="container-fluid">
                    <div className="row">
                        

						<div className="col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5>{this.state.error===''? 'Contagem dos votos' : this.state.error}</h5>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-12">
											<div className="order-graph sm-order-space">
											<div className="row">
												<div className="col-12 col-sm-5">
													<div className="peity-chart-dashboard text-center">
														{this.state.pieItems.length>0 && 
														<Chart
														chartType="PieChart"
														data={this.state.pieItems}
														options={SurveysService.pieOptions}
														graph_id="PieChart"
														width={"100%"}
														height={"280px"}
														legend_toggle
														/> }
													</div>
												</div>
												<div className="col-12 col-sm-7">
													<div className="order-graph-bottom sales-location">
														{this.state.allSurveys.length>0 ? 
															this._loadItems({ colors: SurveysService.pieOptions.slices }) :
															<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />
														}
													</div>
												</div>
											</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Surveys;
