import React, { Component, Fragment } from 'react';
import Datatable from '../common/datatableSubscription';
import {
	getAll,
	getSubscriptionsFormated,
	getAllFiltered,
} from '../../services/Subscriptions';
import { remove as deleteSubscription } from '../../services/Subscriptions';

export class SubscriptionsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: '',
			allSubscriptions: '',
		};
	}
	_SubscriptionsGetAll = async () => {
		const data = await getAll(),
			allSubscriptions = await getSubscriptionsFormated({ data });
		this.setState({ data, allSubscriptions });
	};

	_SubscriptionsGetAllFiltered = async text => {
		const data = await getAllFiltered(text);
		if (data.error) {
			this.setState({
				allSubscriptions: [
					{
						id: '0',
						Nome: (
							<a href="#Nenhum_aluno(a)_encontrado(a)">
								<i className="fa fa-meh-o mr-2"></i>
								Nenhum aluno(a) encontrado(a)
							</a>
						),
						Email: '',
						Metodo: '',
						Início: '',
						Término: '',
						Cadastrado: '',
						Ações: '',
					},
				],
			});
		} else {
			const allSubscriptions = await getSubscriptionsFormated({ data });
			this.setState({ data, allSubscriptions });
		}
	};

	
	
	_listFilter = async () => {
		const searchText = document.getElementById('searchText').value;
		if(searchText){
			this._SubscriptionsGetAllFiltered(searchText);
		}
	};
	
	
	_delete = async props => {
		const deleteSub = await deleteSubscription({ id: props.id_vindi });
		if (deleteSub.success) this._SubscriptionsGetAll();
	};

	componentDidMount() {
		this._SubscriptionsGetAll();
	}

	render() {
		return (
			<Fragment>
				<div className="input-group mb-3">
					<input
						type="text"
						id="searchText"
						className="form-control"
						placeholder="Filtrar por nome ou email"
						aria-label="Filtrar lista"
						aria-describedby="btnFiltrar"
						onChange={e => {
							if(e.target.value.length >= 3){
								this._listFilter()
							}else if(e.target.value.length === 0){
								this._SubscriptionsGetAll()
							}
						}}
					/>
					<div className="input-group-append">
						<button
							className="btn btn-outline-secondary"
							type="button"
							id="btnFiltrar"
							onClick={() => this._listFilter()}
						>
							Filtrar
						</button>
					</div>
				</div>
				<div id="basicScenario" className="report-table"></div>
				<div className="translation-list subscriptionsTable">
					<Datatable
						multiSelectOption={false}
						myData={this.state.allSubscriptions}
						pageSize={
							this.state.allSubscriptions.length <= 10
								? this.state.allSubscriptions.length
								: 10
						}
						pagination={true}
						className="-striped -highlight"
						delete={this._delete}
					/>
				</div>
			</Fragment>
		);
	}
}

export default SubscriptionsTable;
