import React, { Component, Fragment } from 'react';
import Datatable from '../common/datatableNewsletter';
import { getAll, getNewsFormated } from '../../services/Newsletter';
import ReactLoading from 'react-loading';

export class NewsletterTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allNewsletter: ''
		}
	}
	_NewsletterGetAll = async () => {
		const allNewsletter = await getNewsFormated({ data: await getAll() });
		this.setState({ allNewsletter });		
	}
	componentDidMount() {
		this._NewsletterGetAll();
	}
    render() {
        return (
            <Fragment>
                <div className="translation-list newsletterTable">
				{this.state.allNewsletter.length>0 ?
                    <Datatable
                        myData={this.state.allNewsletter}
                        pageSize={this.state.allNewsletter.length <= 10 ? this.state.allNewsletter.length : 10}
                        pagination={true}
                        className="-striped -highlight"
                    />:
					<ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />
					}
                </div>
            </Fragment>
        )
    }
}

export default NewsletterTable;
