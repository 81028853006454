import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { remove as removeClass, update as updateClass, retrieve as retrieveClass, SelectLessons } from '../../services/Classes';
import { SelectTeachers } from '../../services/Teacher';
import { getTeacherDiscussions, save as saveDiscussion } from '../../services/Discussion';
import { getAll as getPresence } from '../../services/Presence';

import 'react-toastify/dist/ReactToastify.css';
var moment = require('moment');

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			presenceOpen: false,
			questionsOpen: false,
			questionsClassId: 0, 
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			lesson_id: '', 
			teacher_id: '', 
			transmition_id: '', 
			date: '', 
			start_at: '', 
			finish_at: '', 
			thumb: '', 
			thumb_type: '', 
			thumb_name: '',
			labelImage: 'Escolher imagem', 
			lessons: '', 
			teachers: '', 
			transmitions: '',
			presence: [],
			questions: []
		}
		this.fileInput = React.createRef();
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleStartChange = this.handleStartChange.bind(this);
		this.handleFinishChange = this.handleFinishChange.bind(this);
	}
	handleNameChange(event) {
		this.setState({ name: event.target.value });
	}
	handleDateChange(event) {
		this.setState({ date: event.target.value });
	}
	handleStartChange(event) {
		this.setState({ start_at: event.target.value });
	}
	handleFinishChange(event) {
		this.setState({ finish_at: event.target.value });
	}
	_setLessonId = lesson_id => { 
		this.setState({ lesson_id });
	}
	_setTeacherId = teacher_id => {		
		this.setState({ teacher_id });
	}
	_setTransmitionId = transmition_id => {		
		this.setState({ transmition_id });
	}

	clearState = () => {
		this.setState({
			openEdit: false,
			open: false,
			presenceOpen: false,
			questionsOpen: false,
			questionsClassId: 0, 
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			lesson_id: '', 
			teacher_id: '', 
			transmition_id: '', 
			date: '', 
			start_at: '', 
			finish_at: '', 
			thumb: '', 
			thumb_type: '', 
			thumb_name: '',
			labelImage: 'Escolher imagem',
		});
	}

	onOpenModal = () => this.setState({ open: true });
	onOpenPresenceModal = () => this.setState({ presenceOpen: true });

	_loadQuestions = async props => {
		const questions = await getTeacherDiscussions({ id: props.id });		
		if (questions.error && this.state.questions.length>0) {
			this.setState({ questions: [] });
		} else {
			this.setState({ questions });
		}
	}
	onOpenQuestionsModal = async props => {
		this._loadQuestions({ id: props.id });
		this.setState({ 
						questionsClassId: props.id, 
						questionsOpen: true 
					});		
	}
	
	onCloseModal = () => {
		this.clearState();
	};
	onClosePresenceModal = () => {
		this.clearState();
	};
	onCloseQuestionsModal = () => {
		this.clearState();
	}

	onOpenModalEdit = async props => {
		const classDetails = await retrieveClass({ id: props.id });
		let dateFormat = '';
		if (classDetails.start_at!=='') {
			const dateSplit = classDetails.date.split("T");
			dateFormat = dateSplit[0]!==undefined ? moment(dateSplit[0]).format("YYYY-MM-DD") : null;
		}
		this.setState({ 
						idEdit: props.id, 
						name: props.name, 
						lesson_id: classDetails.lesson_id,  
						teacher_id: classDetails.teacher_id,  
						transmition_id: classDetails.transmition_id,  
						date: dateFormat,  
						start_at: classDetails.start_at,  
						finish_at: classDetails.finish_at, 
						labelImage: props.thumb!==null ? <img src={props.thumb} alt="thumb da aula" className="img_thumb" /> : null, 
						openEdit: true,
					});
	}

	onOpenPresenceModalEdit = async id => {
		getPresence(id).then(data => {
			if (data.error) throw data.error;
			else {
				this.setState({presence: data})
				this.setState({presenceOpen: true});
			}
		}).catch(err => {
			console.error(err.message);
			this.setState({presence: {error: err.message}})
			this.setState({presenceOpen: true});
		});
	}

	onCloseModalEdit = () => {
		this.clearState();
	}

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeClass = async props => {
		await removeClass(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Aula removida com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateClass();
		return false;
	}

	_updateClass = async () => {
		let data = {
			id: this.state.idEdit,
			name: this.state.name,
			lesson_id: this.state.lesson_id, 
			teacher_id: this.state.teacher_id, 
			transmition_id: this.state.transmition_id, 
			date: this.state.date, 
			start_at: this.state.start_at, 
			finish_at: this.state.finish_at
		}
		if (this.state.thumb!=='' && this.state.thumb_type!=='') {
			data = {
				...data,
				thumbnail: this.state.thumb,
				thumb_type: this.state.thumb_type,
				file_name: this.state.thumb_name
			}
		}
		const response = await updateClass(data);

		if (response.status) {
			this.props.update();
			toast.success("Aula editada com sucesso!");
		} else {
			toast.success("Erro ao salvar");
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	_changeImage = () => {
		if (this.fileInput.current.files.length>0) {
			const file = this.fileInput.current.files[0];
			var reader  = new FileReader();
			const type = file.type,
				  size = file.size,
				  fileName = file.name;
			reader.onloadend = () => {
				if (size<251658240) {					
					this.setState({ 
									thumb: reader.result, 
									thumb_type: type, 
									thumb_name: fileName ,
									labelImage: <img src={reader.result} alt="thumbnail" className="img_thumb" />
								})
				} else {
					this.setState({ labelImage: "Escolher imagem" })
					toast.error("A imagem enviada é muito grande. Ela deve ter no máximo 1 MB.");
				}
			}

			if (file) {
				reader.readAsDataURL(file);
			} else {
				toast.error("Erro ao pegar o conteúdo da imagem.");
			}			
		} else { 
			toast.error("Erro ao pegar o conteúdo da imagem.");
		}
	}

	_sendResponse = async props => {
		const id = props.id, 
			  text = document.getElementById("resposta_"+id).value;

		const response = await saveDiscussion({ id, text });
		if (response.status==='success') {
			document.getElementById("resposta_"+id).value = "";
			this._loadQuestions({ id: this.state.questionsClassId });
			toast.success("Resposta salva com sucesso.");
		}
	}

	_getAnswer = props => {
		const questionsFormated = props.questions.map(subItem => {
			if (props.id===subItem.discussion_id) {
				return (<>
					<div className="col-12 col-sm-4 pr-0">
						<small className="text-primary">{subItem.name}</small> 
						<small className="text-muted">{moment(subItem.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}</small>
					</div>
					<div className="col-12 col-sm-8 pl-1">
						<strong className="text-secondary">{subItem.text}</strong>
					</div>
				</>);
			}
		});
		return questionsFormated;
	}

	componentDidMount() {
		if (this.props.lessons!=='') this.setState({ lessons: this.props.lessons });
		if (this.props.teachers!=='') this.setState({ teachers: this.props.teachers });
		if (this.props.transmitions!=='') this.setState({ transmitions: this.props.transmitions });
	}
	componentDidUpdate() {
		if (this.props.lessons!=='' && (this.state.lessons==='' || this.props.lessons!==this.state.lessons)) { 
			this.setState({ lessons: this.props.lessons });
		}
		if (this.props.teachers!=='' && (this.state.teachers==='' || this.props.teachers!==this.state.teachers)) { 
			this.setState({ teachers: this.props.teachers });
		}
		if (this.props.transmitions!=='' && (this.state.transmitions==='' || this.props.transmitions!==this.state.transmitions)) { 
			this.setState({ transmitions: this.props.transmitions });
		}
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];
		
		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

			columns.push({
				Header: <b>Perguntas</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => {
					return (
					<div>
						{/* <span onClick={() =>  this.onOpenPresenceModalEdit(row.original.id)}
						title="Lista de presença">
							<i
								className="fa fa-list default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									cursor: 'pointer'
								}}
							></i>
						</span> */}

						<span onClick={() =>  this.onOpenQuestionsModal({ id: row.original.id })} title="Perguntas da aula">
							<i
								className="fa fa-question-circle-o default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)},
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});

		const presenceItemStyle = {
				width: "250px"
			};


		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				
				<Modal open={this.state.questionsOpen} onClose={this.onCloseQuestionsModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Perguntas
						</h5>
					</div>
					<div className="modal-body questionsModal">
						{this.state.questions.error &&
							<>
								<h4>
									<i className="fa fa-meh-o fa-3x mr-2"></i>
									Nenhuma pergunta encontrada
								</h4>
							</>
						}
						{this.state.questions.length > 0 
							&& this.state.questions.map(item => {
							if (item.discussion_id===null) {
								return (
								<div className="questionItem" key={item.id}>
									<div className="row">
										<div className="col-12 col-sm-4 pr-0">
											<small className="text-primary">{item.name}</small> 
											<small className="text-muted">{moment(item.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}</small>
										</div>
										<div className="col-12 col-sm-8 pl-1">
											<strong className="text-secondary">{item.text}</strong>
										</div>
									</div>
									<div className="row">
										{ this._getAnswer({ id: item.id, questions: this.state.questions}) }
									</div>
									<div className="row" id={"pergunta_"+ item.id }>
										<div className="col-12 pt-2">
											<div className="input-group">
												<input 
													type="text" 
													className="form-control" 
													id={"resposta_"+ item.id }
												/>
												<div className="input-group-append">
													<div 
														className="btn btn-primary" 
														onClick={() => this._sendResponse({ id: item.id })}
													>
														Enviar
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>)
							}
								})
						}
						{this.state.questions.length === 0 && 
							<>
								<h4>
									<i className="fa fa-meh-o mr-2"></i>
									Nenhuma pergunta encontrada
								</h4>
							</> 
						}
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.onCloseQuestionsModal() }
						>
							Fechar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.presenceOpen} onClose={this.onClosePresenceModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Lista de presença
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							{this.state.presence.error &&
								<div>{this.state.presence.error}</div>
							}
							{this.state.presence.length > 0 
								&& this.state.presence.map(item => 
									<div style={presenceItemStyle}>{
										item.name}
									</div>
								)
							}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.onClosePresenceModal() }
						>
							Ok
						</button>
					</div>
				</Modal>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja remover a aula <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected!=='' ? 
											this._removeClass({ id: this.state.rowSelected }) :
											toast.error("Erro ao deletar a aula.") }
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Aula
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="row">
								<div className="col-6">
									<div className="form-group">
										<label>Nome da aula</label>
										<input 
											type="text" 
											className="form-control" 
											id="name" 
											placeholder="Nome da aula" 
											value={this.state.name} 
											onChange={this.handleNameChange}
										/>
									</div>
									<div className="form-group">
										<label>Disciplina</label>
										<SelectLessons lessons={this.state.lessons} id={this.state.lesson_id} update={this._setLessonId} />
									</div>
									<div className="form-group">
										<label>Professor</label>
										<SelectTeachers teachers={this.state.teachers} id={this.state.teacher_id} update={this._setTeacherId} />								
									</div>
								</div>
								<div className="col-6 pt-4">
									<div className="custom-file mt-1">
										<input 
											type="file" 
											className="fileInput" 
											ref={this.fileInput}
											id="file" 
											onChange={() => this._changeImage()} 
										/>
										<label className="custom-file-label" for="file">{this.state.labelImage}</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-group">
										<label>Data</label>
										{this.state.date !=='' &&
										<input 
											type="date" 
											className="form-control" 
											id="data" 
											placeholder="Data da aula" 
											value={this.state.date}
											onChange={this.handleDateChange}
										/> }
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label>Início</label>
										<input 
											type="time" 
											className="form-control" 
											id="start" 
											placeholder="Início da aula" 
											value={this.state.start_at}
											onChange={this.handleStartChange}
										/>
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label>Término</label>
										<input 
											type="time" 
											className="form-control" 
											id="finish" 
											placeholder="Término da aula" 
											value={this.state.finish_at}
											onChange={this.handleFinishChange}
										/>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateClass() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
