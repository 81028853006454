import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import MentoringTable from './mentoring-table';
import { ToastContainer, toast } from 'react-toastify';
import { getAll, getClassesFormated, save as saveClass, SelectLessons, SelectTeachers, SelectDay } from '../../services/Mentoring';
import { getAll as getAllLessons } from '../../services/Lesson';
import { getAll as getAllTeachers } from '../../services/Teacher';
import { getAll as getAllTransmitions } from '../../services/TransmissionService';
import Modal from 'react-responsive-modal';
import { ptToEng } from '../../utils/convertDay';

export class Mentoring extends Component {
	constructor(props) {
		super(props);

		const daysWeek = [
			{ id: 1, name: 'Segunda' },
			{ id: 2, name: 'Terça' },
			{ id: 3, name: 'Quarta' },
			{ id: 4, name: 'Quinta' },
			{ id: 5, name: 'Sexta' }];

		this.state = {
		  allClasses: '',
		  lessons: '', 
		  teachers: '', 
		  transmitions: '', 
		  openEdit: false,
		  daysWeek,

		  teacher_id: '', 
		  lesson_id: '', 
		  thumb: '',
		  thumb_type: '',
		  thumb_name: '', 
		  labelImage: 'Escolher imagem',
		  showVimeo: false,
		  showZoom: false
		}
		this.fileInput = React.createRef();
	}
	_setTeacherId = teacher_id => this.setState({ teacher_id });
	_setLessonId = lesson_id => this.setState({ lesson_id });

	clearState = () => {
		this.setState({
		  openEdit: false,
		  teacher_id: '', 
		  lesson_id: '', 
		  thumb: '',
		  thumb_type: '',
		  thumb_name: '', 
		  labelImage: 'Escolher imagem',
		  showVimeo: false,
		  showZoom: false
		});
	}

	onOpenModalEdit = () => this.setState({ openEdit: true });
	onCloseModalEdit = () => {
		this.clearState();
	}

	_sendForm = e => {
		e.preventDefault();
		this._saveNew();
		return false;
	}
	
	_saveNew = async () => {
		let name = document.getElementById("name").value,
			lesson_id = document.getElementById("lesson_id").value,
			teacher_id = document.getElementById("teacher_id").value,
			week = ptToEng(document.getElementById("day_id").value),
			start_at = document.getElementById("start_at").value,
			finish_at = document.getElementById("finish_at").value,
			url = document.getElementById("url-vimeo").value || document.getElementById("url-zoom").value , 
			postData = { 
							name, 
							lesson_id, 
							teacher_id, 
							week, 
							start_at, 
							finish_at,
							thumbnail: this.state.thumb!=='' ? this.state.thumb : null,
							thumb_type: this.state.thumb_type!=='' ? this.state.thumb_type : null,
							file_name: this.state.thumb_name!=='' ? this.state.thumb_name : null,
							url,
						};
		const response = await saveClass(postData);

		if (response) {
			toast.success("Mentoria salva com sucesso!");
			this._ClassesGetAll();
		} else {
			toast.error(response);
		}
			this.onCloseModalEdit();
	}

	_ClassesGetAll = async () => {
		const allData = await getAll(); 

		if (allData.error) {
			this.setState({ allClasses: [{ 
				id: 	'0',
				title:	'',
				thumb: 	'', 
				Nome: 		<span className='text-danger'>
								<i className="fa fa-meh-o mr-2"></i>
								Nenhuma Mentoria encontrada
							</span>,
				Professor: 	'',
				"Dia da Semana":	'', 
			}] });
		} else {
			const allClasses = await getClassesFormated({ data: allData });
			this.setState({ allClasses });
		}
	}

	_LessonsGetAll = async () => {
		const lessons = await getAllLessons();
		this.setState({ lessons });
	}

	_TeachersGetAll = async () => {
		const teachers = await getAllTeachers();
		this.setState({ teachers });
	}

	_TransmitionsGetAll = async () => {
		const transmitions = await getAllTransmitions();
		this.setState({ transmitions });
	}

	_changeImage = () => {
		if (this.fileInput.current.files.length>0) {
			const file = this.fileInput.current.files[0];
			var reader  = new FileReader();
			const type = file.type,
				  size = file.size,
				  fileName = file.name;
			reader.onloadend = () => {
				if (size<251658240) {					
					this.setState({ 
									thumb: reader.result, 
									thumb_type: type, 
									thumb_name: fileName ,
									labelImage: <img src={reader.result} alt="thumbnail" className="img_thumb" />
								})
				} else {
					this.setState({ labelImage: "Escolher imagem" })
					toast.error("A imagem enviada é muito grande. Ela deve ter no máximo 1 MB.");
				}
			}

			if (file) {
				reader.readAsDataURL(file);
			} else {
				toast.error("Erro ao pegar o conteúdo da imagem.");
			}			
		} else { 
			toast.error("Erro ao pegar o conteúdo da imagem.");
		}
	}
	_changeType = () => {
		const transmitionType = document.getElementById("transmition_type").value;
		if (transmitionType==='vimeo') {			
			this.setState({ showZoom: false, showVimeo: true });
		} else if (transmitionType==='zoom') {
			this.setState({ showVimeo: false, showZoom: true });
		} else {
			this.setState({ showZoom: false, showVimeo: false });
		}
	}

	componentDidMount() {
		this._ClassesGetAll();
		this._LessonsGetAll();
		this._TeachersGetAll();
		this._TransmitionsGetAll();
	}

    render() {	
        return (
            <div>
                <Breadcrumb title="Mentorias" backButton={false} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<div className="btn btn-primary pull-right" onClick={() => this.onOpenModalEdit()}>
										Criar Mentoria
									</div>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <MentoringTable 
											allClasses={this.state.allClasses} 
											update={this._ClassesGetAll} 
											lessons={this.state.lessons} 
											teachers={this.state.teachers} 
											transmitions={this.state.transmitions} 
										/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header" style={{ width: '58vw' }}>
						<h5 className="modal-title f-w-600">
							Nova Mentoria
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="row">
								<div className="col-6">
									<div className="form-group">
										<label>Nome da mentoria</label>
										<input type="text" className="form-control" id="name" placeholder="Nome da mentoria" />
									</div>
									<div className="form-group">
										<label for="exampleFormControlSelect1">Disciplina</label>
										<SelectLessons lessons={this.state.lessons} />								
									</div>
									<div className="form-group">
										<label for="exampleFormControlSelect1">Professor</label>
										<SelectTeachers teachers={this.state.teachers} />								
									</div>
								</div>
								<div className="col-6 pt-4">
									<div className="custom-file mt-1">
										<input 
											type="file" 
											className="fileInput" 
											ref={this.fileInput}
											id="file" 
											onChange={() => this._changeImage()} 
										/>
										<label className="custom-file-label" for="file">{this.state.labelImage}</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<div className="form-group">
										<label for="exampleFormControlSelect1">Tipo de Transmissão</label> 
										<select 
											className="form-control" 
											id="transmition_type"
											onChange={() => this._changeType()}
										>
											<option value=''>Selecione um Tipo</option>
											<option value='vimeo'>Vimeo</option>
											<option value='zoom'>Zoom</option>
										</select>
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label for="exampleFormControlSelect1">Dia da Semana</label>
										<SelectDay days={this.state.daysWeek} />
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label>Início</label>
										<input type="time" className="form-control" id="start_at" placeholder="Início da mentoria" />
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label>Término</label>
										<input type="time" className="form-control" id="finish_at" placeholder="Término da mentoria" />
									</div>
								</div>
							</div>

							<div className="row" style={{ display: this.state.showVimeo ? 'flex' : 'none' }}>
								<div className="col-12">
									<div className="form-group">
										<label>URL de transmissão do Vimeo</label>
										<input type="text" className="form-control" id="url-vimeo" placeholder="URL da mentoria" />
									</div>
								</div>
							</div>

							<div className="row" style={{ display: this.state.showZoom ? 'flex' : 'none' }}>
								<div className="col-12">
									<div className="form-group">
										<label>URL de transmissão do Zoom</label>
										<input type="text" className="form-control" id="url-zoom" placeholder="Url da mentoria" />
									</div>
								</div>
							</div>
							
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._saveNew() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
            </div>
        )
    }
}

export default Mentoring;
