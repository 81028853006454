import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import TeacherTable from './teacher-table';
import { ToastContainer, toast } from 'react-toastify';
import { getAll, getTeacherFormated, save as saveTeacher } from '../../services/Teacher';
import Modal from 'react-responsive-modal';

export class Teacher extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allTeacher: '',
		  openEdit: false,
		  labelImage: 'Escolher imagem',
		  thumb: '',
		  thumb_type: '',
		  thumb_name: '', 
		}
		this.fileInput = React.createRef();
	}

	clearState = () => {
		this.setState({
		  openEdit: false,
		  labelImage: 'Escolher imagem',
		  thumb: '',
		  thumb_type: '',
		  thumb_name: '', 
		})
	}

	onOpenModalEdit = () => this.setState({ openEdit: true });
	onCloseModalEdit = () => {
		this.clearState();
	}

	_changeImage = () => {
		if (this.fileInput.current.files.length>0) {
			const file = this.fileInput.current.files[0];
			var reader  = new FileReader();
			const type = file.type,
				  size = file.size,
				  fileName = file.name;
			reader.onloadend = () => {
				if (size<251658240) {					
					this.setState({ 
									thumb: reader.result, 
									thumb_type: type, 
									thumb_name: fileName ,
									labelImage: <img src={reader.result} alt="thumbnail" className="img_thumb" />
								})
				} else {
					this.setState({ labelImage: "Escolher imagem" })
					toast.error("A imagem enviada é muito grande. Ela deve ter no máximo 1 MB.");
				}
			}

			if (file) {
				reader.readAsDataURL(file);
			} else {
				toast.error("Erro ao pegar o conteúdo da imagem.");
			}			
		} else { 
			toast.error("Erro ao pegar o conteúdo da imagem.");
		}
	}
	_sendForm = e => {
		e.preventDefault();
		this._saveNew();
		return false;
	}
	_saveNew = async () => {
		let name = document.getElementById("name").value;
		const response = await saveTeacher({ 
												name,
												thumbnail: this.state.thumb!=='' ? this.state.thumb : null,
												thumb_type: this.state.thumb_type!=='' ? this.state.thumb_type : null,
												file_name: this.state.thumb_name!=='' ? this.state.thumb_name : null 
											});

		if (response) {
			toast.success("Professor salvo com sucesso!");
			this._TeachersGetAll();
		} else {
			toast.error(response);
		}
			this.onCloseModalEdit();
	}

	_TeachersGetAll = async () => {
		const allData = await getAll();
		if (allData.error) {
			this.setState({ allTeacher: [{ 
				id: 	'0',
				title:	'',
				thumb: 	'', 
				Nome: 		<span className='text-danger'>
								<i className="fa fa-meh-o mr-2"></i>
								Nenhum Professor encontrado
							</span>,
				Professor: 	'',
				Data:	'', 
			}] });
		} else {
			const allTeacher = await getTeacherFormated({ data: allData });
			this.setState({ allTeacher });
		}
	}

	componentDidMount() {
		this._TeachersGetAll();
	}

    render() {
        return (
            <div>
                <Breadcrumb title="Professores" backButton={false} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<div className="btn btn-primary pull-right" onClick={() => this.onOpenModalEdit()}>
										Criar Professor
									</div>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <TeacherTable lessons={this.state.allTeacher} update={this._TeachersGetAll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Novo Professor
						</h5>
					</div>
					<div className="modal-body">
						<form onSubmit={(e) => this._sendForm(e) }>
							<div className="form-group">
								<label>Nome do professor</label>
								<input type="text" className="form-control" id="name" placeholder="Nome do professor" />
							</div>
							
							<div className="custom-file mt-1">
								<input 
									type="file" 
									className="fileInput" 
									ref={this.fileInput}
									id="file" 
									onChange={() => this._changeImage()} 
								/>
								<label className="custom-file-label" for="file">{this.state.labelImage}</label>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._saveNew() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
            </div>
        )
    }
}

export default Teacher;
