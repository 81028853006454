import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import SubscriptionsTable from './subscription-table';

export class Subscriptions extends Component {
	render() {
		return (
			<div>
				<Breadcrumb
					title="Assinaturas"
					parent="Usuários"
					backButton={false}
					origin=""
				/>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5>
										Usuários
										<span className="pull-right">
											<small className="text-muted mr-2">
												Status:
											</small>
											<strong className="text-success mr-3">
												Ativo
											</strong>
											<strong className="text-danger">
												Inativo
											</strong>
										</span>
									</h5>
								</div>
								<div className="card-body">
									<div
										id="basicScenario"
										className="report-table"
									>
										<SubscriptionsTable />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Subscriptions;
