import React from 'react';
import { Star } from 'react-feather';
import ReactLoading from 'react-loading';
const axios = require('axios');

export const getAll = async () => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/survey' )
								.then(response => response.data)
								.catch(error => error)
	return response;
}

export const pieOptions = {
	title: "Relatório WR",
	pieHole: 1,
	slices: [
	  { color: "blue", },
	  { color: "indigo", },
	  { color: "purple", },
	  { color: "pink", },
	  { color: "red", },
	],
	tooltip: {
	  showColorCode: false,
	},
	chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
	legend: "none",
}

export const starVotes = nota => {
	switch (nota) {
		case 5:
		  return <> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#FFD700" /> </>
		case 4:
			return <> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#CCC" /> </>
		case 3:
			return <> <Star color="#FFD700" /> <Star color="#FFD700" /> <Star color="#FFD700" />  <Star color="#CCC" />  <Star color="#CCC" /> </>
		case 2:
			return <> <Star color="#FFD700" /> <Star color="#FFD700" />  <Star color="#CCC" />  <Star color="#CCC" />  <Star color="#CCC" /> </>
		case 1:
			return <> <Star color="#FFD700" />  <Star color="#CCC" />  <Star color="#CCC" />  <Star color="#CCC" />  <Star color="#CCC" /> </>
		default: 
			return <> <Star color="#FFD700" />  <Star color="#CCC" />  <Star color="#CCC" />  <Star color="#CCC" />  <Star color="#CCC" /> </>
	}
}

export const loadItems = props => {
	if (props.surveys.length===0) return <ReactLoading type={'bars'} color={"#27286f"} height={100} width={100} />;
	const items = [];
	let c=0;
	let totalVotes = 0;
	props.surveys.forEach((survey) => {
		totalVotes = totalVotes+survey.votes;
	});		
	let pieItems = [["Votos", "Agrupados por nota"]];
	props.surveys.forEach((survey) => {
		const votes = survey.votes,
			  percent = (votes*100)/totalVotes;
		pieItems.push([survey.note+" estrelas", votes]);
	  items.push(
		<div className="media" key={'survey_'+survey.note}>
				<div className="media-body">
				<h6 className="mb-0 mr-0">
					<strong className="votes" style={{ color: props.colors[c].color!==undefined ? props.colors[c].color : "#F00" }}>
						{percent.toFixed(0)}%
					</strong> &nbsp; 
					{starVotes(survey.note)}
					<span className="pull-right totalVotes">Total de&nbsp;
						<strong className="votes" style={{ color: props.colors[c].color!==undefined ? props.colors[c].color : "#F00" }}>
							{votes}
						</strong> votos
					</span>
				</h6>
			</div>
		</div>
	  );
	  c++;
	});	
	return { items, pieItems };
}
