import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { retrieve as retrieveSubscription } from '../../services/Subscriptions';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import moment from 'moment';
export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			open: false,
			idSubVindiSelected: '', 
			rowSelected: '',
			titleRowSelected: '',
			emailRowSelected: '',
			registerRowSelected: '',
			startRowSelected: '',
			endRowSelected: '',
			confirmBtn: 'Confirmar',
			pageSize: '', 
			myClass: '', 
			pagination: '', 
			myData: '', 			
		};
	}

	onOpenModal = async () => {
		if (this.state.rowSelected!=='') {
			const subscription = await retrieveSubscription({ id: this.state.rowSelected });
			if (subscription.sub_gateway_id!=='') this.setState({ idSubVindiSelected: subscription.sub_gateway_id });
		}
		this.setState({ open: true });
	}

	onCloseModal = () => this.setState({ open: false });

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
	_closeDelete = () => {
		this.onCloseModal();
		this.setState({ confirmBtn: 'Confirmar' });
	}
	_deleteSubscription = async () => {		
		this.setState({ confirmBtn: <ReactLoading type={'bars'} color={"#27286f"} height={20} width={100} /> })
		if (this.state.idSubVindiSelected!=='') {
				this.props.delete({ 
					id_vindi: this.state.idSubVindiSelected
				});
		} else toast.error("Ocorreu um erro ao deletar a assinatura");		
	}


	componentDidMount() {
		if (this.props.pageSize!=='') this.setState({ pageSize: this.props.pageSize });
		if (this.props.myClass!=='') this.setState({ myClass: this.props.myClass });
		if (this.props.pagination!=='') this.setState({ pagination: this.props.pagination });
		if (this.props.myData!=='') this.setState({ myData: this.props.myData });
	}

	componentDidUpdate() {
		if (this.props.pageSize!=='' && this.state.pageSize!==this.props.pageSize) this.setState({ pageSize: this.props.pageSize });
		if (this.props.myClass!=='' && this.state.myClass!==this.props.myClass) this.setState({ myClass: this.props.myClass });
		if (this.props.pagination!=='' && this.state.pagination!==this.props.pagination) this.setState({ pagination: this.props.pagination });
		if (this.props.myData!=='' && this.state.myData!==this.props.myData) {
			this.setState({ myData: this.props.myData });
			if (this.state.open) this._closeDelete();
		}
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.state;
		const columns = [];
		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				sortMethod: (a, b) => {
					if(moment(a.props.children.props.children , "DD/MM/YYYY", true).isValid() && 
						moment(b.props.children.props.children , "DD/MM/YYYY", true).isValid()){
						const aDate = moment(a.props.children.props.children, "DD/MM/YYYY");
						const bDate = moment(b.props.children.props.children, "DD/MM/YYYY");
						if(moment(aDate).isSame(bDate)){
							return moment(aDate).isAfter(bDate) ? 1 : -1;
						}
						return moment(aDate).isAfter(bDate) ? 1 : -1;
					}else{
					 if(a.props.children.props.children.length > 2 && a.props.children.props.children.length > 2){
					  if (a.props.children.props.children.localeCompare(b.props.children.props.children) === 0) {
						return a.props.children.props.children.localeCompare(b.props.children.props.children);
					  }
						return a.props.children.props.children.localeCompare(b.props.children.props.children);
					 }else{
						if(!a.props.children.props.children[0]){
						  return a > b ? 1 : -1;
						}
					 }
				   }
                  },
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}
			columns.push({
				Header: <b>Ações</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => (
					<div>
						<Link to={`/subscription/${row.original.id}`} title="Detalhes">
							<span title="Histórico dos pagamentos">
								<i
									className="fa fa-credit-card default-color"
									style={{
										width: 35,
										fontSize: 20,
										padding: 11,
									}}
								></i>
							</span>
						</Link>
						{row.original.status==='active' &&
						<span onClick={() => {
							this.setState({ titleRowSelected: row.original.Nome });
							this.setState({ emailRowSelected: row.original.Email });							
							this.setState({ registerRowSelected: row.original.Cadastrado });
							this.setState({ startRowSelected: row.original.Início });
							this.setState({ endRowSelected: row.original.Término });
							this.setState({ rowSelected: row.original.id }, () => this.onOpenModal());
						}} title="Remover acesso" className={"ml-2 btnRemove_"+row.original.id}>
							<i
								className="fa fa-remove cursor-pointer"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
								}}
							></i>
						</span> }
					</div>
				),
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});
		return (
			<Fragment>
				{myData!==undefined && 
				<>
					{myData.length>0 && 
						<ReactTable
							data={myData}
							columns={columns}
							defaultPageSize={pageSize}
							className={myClass}
							showPagination={pagination}
							previousText="Voltar"
							nextText="Próximo"
							loadingText="Carregando..."
							noDataText="Sem dados"
							pageText="Página"
							ofText="de"
							rowsText="Linhas"
						/> }
				</>}
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header bg-danger text-white">
						<h5
							className="modal-title f-w-600 text-white"
							id="exampleModalLabel2"
						>
							Atenção! Não será mais possível reativar esta assinatura.*
						</h5>
					</div>
					<div className="modal-body">
							<div>
								<label>
									Você realmente deseja remover o acesso do usuário&nbsp;
									<strong>{this.state.titleRowSelected}</strong> à Plataforma WR?
								</label>
								<hr />
								<p> 
									Email: <strong>{this.state.emailRowSelected}</strong>
								<br />
									Cadastrado: <strong>{this.state.registerRowSelected}</strong>
								<br />
									Período: <strong>{this.state.startRowSelected} - {this.state.endRowSelected}</strong>
								</p>
							</div>
							<div className="card">
								<div className="card-body bg-danger">
									<strong>* </strong> Para que este usuário tenha acesso novamente, será preciso 
									acessar a plataforma e contratar novamente um plano.
								</div>
							</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._deleteSubscription()}
						>
							{this.state.confirmBtn}
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
