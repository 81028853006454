import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import UserTable from './user-table';
import { ToastContainer, toast } from 'react-toastify';
import { getAll, getUserFormated, save as saveUser } from '../../services/User';
import { getAll as getAllTeachers, SelectTeachers } from '../../services/Teacher';
import Modal from 'react-responsive-modal';

export class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  allLessons: '',
		  openEdit: false,
		  teachers: '', 
		  teacherDisabled: true
		}
	}

	_TeachersGetAll = async () => {
		const teachers = await getAllTeachers();
		this.setState({ teachers });
	}

	onOpenModalEdit = () => this.setState({ openEdit: true });
	onCloseModalEdit = () => this.setState({ openEdit: false });

	_sendForm = e => {
		e.preventDefault();
		this._saveNew();
		return false;
	}
	_saveNew = async () => {
		let name = document.getElementById("name").value, 
			login = document.getElementById("login").value, 
			password = document.getElementById("password").value, 
			passwordValidation = document.getElementById("password-validation").value,
			is_admin = document.getElementById("is_admin").value,
			teacher_id = document.getElementById("teacher_id").value;

		if (password!==passwordValidation) return toast.error("As senha estão diferentes!");

		const response = await saveUser({ 
											name, 
											login, 
											password, 
											is_admin: is_admin==='true' ? true : false, 
											teacher_id
										});

		if (response) {
			toast.success("Usuário salvo com sucesso!");
			this._userGetAll();
		} else {
			toast.error(response);
		}
			this.onCloseModalEdit();
	}

	_userGetAll = async () => {
		const allData = await getAll();
		if (allData.error) {
			this.setState({ allLessons: [{ 
				id: 	'0',
				name:	'', 
				Nome: 		<span className='text-danger'>
								<i className="fa fa-meh-o mr-2"></i>
								Nenhum Usuário encontrado
							</span>,
				Cadastrado: 	'',
				Editado:	'', 
			}] });
		} else {
			const allLessons = await getUserFormated({ data: allData });
			this.setState({ allLessons });		
		}
	}
	_changePermission = () => {
		const is_admin = document.getElementById("is_admin").value;
		let teacherDisabled = false;
		
		if (is_admin==='true') {
			teacherDisabled = true;
			document.getElementById("teacher_id").value = ''
		}

		this.setState({ teacherDisabled });
	}

	componentDidMount() {
		this._userGetAll();
		this._TeachersGetAll();
	}

    render() {
        return (
            <div>
                <Breadcrumb title="Usuários" backButton={false} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<div className="btn btn-primary pull-right" onClick={() => this.onOpenModalEdit()}>
										Criar Usuário
									</div>
                                </div>
                                <div className="card-body">
                                    <div id="basicScenario" className="report-table">
                                        <UserTable lessons={this.state.allLessons} update={this._userGetAll} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Novo Usuário
						</h5>
					</div>
					<form onSubmit={(e) => this._sendForm(e) }>
						<div className="modal-body">
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Nome do usuário</label>
										<input type="text" className="form-control" id="name" placeholder="Nome do usuário" />
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Login do usuário</label>
										<input type="email" className="form-control" autoComplete="username" id="login" placeholder="Login do usuário" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Senha do usuário</label>
										<input type="password" className="form-control" autoComplete="new-password" id="password" placeholder="Senha do usuário" />
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Confirme a Senha</label>
										<input type="password" className="form-control" autoComplete="new-password" id="password-validation" placeholder="Digite a senha novamente" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Nível de Acesso</label>
											<select 
												className="form-control" 
												id="is_admin" 
												onChange={() => this._changePermission()}
											>
												<option value={true}>Admin</option>
												<option value={false}>Professor</option>
											</select>
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label for="exampleFormControlSelect1">Professor</label>
										<SelectTeachers teachers={this.state.teachers} disabled={ this.state.teacherDisabled } />
									</div>
								</div>
							</div>
						</div>
					</form>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._saveNew() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
            </div>
        )
    }
}

export default Users;
