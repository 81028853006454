import React from 'react';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {	
const response = await axios.get( process.env.REACT_APP_API_URL + '/content/all' )
	.then(response => response.data)
	.catch(error => { return { error: error} })
	return response;
}

export const getContentFormated = async props => {
	let contentFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		contentFormated = props.data.map((content) => {
			if (content.deleted_at || content.active===0) className='text-danger'
			else className='text-success'

			return { 
				id: content.id,
				name: 		content.title,
				author: 	content.author,
				thumb: 		content.url,
				Título:		<span className={className}>{content.title}</span>,
				Link:		<a href={content.url} className={className} target="_blank" rel="noopener noreferrer">Abrir</a>,
				Autor:		<span className={className}>{content.author}</span>,
				Cadastrado:	<span className={className}>
								{moment(content.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>
			}
		});
	}
	return contentFormated;
}

export const save = async props => {	
	const response = await axios.post( process.env.REACT_APP_API_URL + '/content/upload', { 
																						name: props.name,
																						author: props.author, 
																						year: props.year, 
																						vol: '', 
																						frente: '',
																						thumbnail: props.thumbnail!=='' ? props.thumbnail : null,
																						thumb_type: props.thumb_type!=='' ? props.thumb_type : null,
																						file_name: props.file_name!=='' ? props.file_name : null 
																					})
								.then(response => response.data)
								.catch(error => error.response.data )
	return response;
}

export const update = async props => {
	let data = { 
		name: props.name,
		author: props.author,
		year: props.year,
		vol: props.vol, 
		frente: props.frente,
	}
	
	if (props.thumbnail!==undefined && props.thumb_type!==undefined) {
		data = {
			...data,
			thumbnail: props.thumbnail,
			thumb_type: props.thumb_type,
			file_name: props.file_name
		}
	}
	const response = await axios.put( process.env.REACT_APP_API_URL + '/content/' + props.id, data)
								.then(response => response.data)
								.catch(error => error.response.data )
	return response;
}

export const retrieve = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/content/show/' + props.id )
		.then(response => response.data)
		.catch(error => error.response.data )
		return response;
}

export const remove = async props => {
	try {
		const encodedString = Buffer.from(props.id).toString('base64');
		const response = await axios.delete( process.env.REACT_APP_API_URL + '/content/' + encodedString )
			.then(response => response.data)
			.catch(error => error.response.data )
			return response;
		}
		catch (e) {
		   return { errorObject: e };
		}
}

export const getAllClassContent = async props => {	
const response = await axios.get( process.env.REACT_APP_API_URL + '/classcontent/' + props.id )
	.then(response => response.data)
	.catch(error => { return { error: error.response.data} })
	return response;
}

export const getAllLessonsContent = async props => {	
const response = await axios.get( process.env.REACT_APP_API_URL + '/lessoncontent/' + props.id )
	.then(response => response.data)
	.catch(error => { return { error: error.response.data} })
	return response;
}

export const addClassContent = async props => {
	let data = { 
		class_id: props.class_id,
		content_id: props.content_id,
		active: 1
	}
	const response = await axios.post( process.env.REACT_APP_API_URL + '/classcontent', data)
								.then(response => response.data)
								.catch(error => error.response.data )
	return response;
}

export const addLessonContent = async props => {
	let data = { 
		lesson_id: props.lesson_id,
		content_id: props.content_id,
		active: 1
	}

	console.log(">> URL: ", process.env.REACT_APP_API_URL + '/lessoncontent');

	console.log(">> data: ", data);

	const response = await axios.post( process.env.REACT_APP_API_URL + '/lessoncontent', data)
								.then(response => response.data)
								.catch(error => error.response.data )
	return response;
}

export const removeClassContent = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/classcontent/' + props.id)
								.then(response => response.data)
								.catch(error => error.response.data )
	return response;
}

export const removeLessonContent = async props => {

	console.log(">> removeLessonContent > URL: ", process.env.REACT_APP_API_URL + '/lessoncontent/' + props.id);

	const response = await axios.delete( process.env.REACT_APP_API_URL + '/lessoncontent/' + props.id)
								.then(response => response.data)
								.catch(error => error.response.data )
	return response;
}

export const SelectLessons = props => {
	let lessons = [];
	if (props.lessons.length>0) lessons = props.lessons.map(lesson => <option key={lesson.id} value={lesson.id}>{lesson.value}</option>)

	return <select 
				className="custom-select" 
				id="lesson_id" 
			>
			{lessons.length>0 ?
			<>
				<option value=''>Selecione uma disciplina</option>
				{lessons}
			</> :
				<option value='0' disabled>Nenhum disciplina cadastrada</option> 
			}
		   	</select>;
}

export const SelectClasses = props => {
	let lessons = [];
	if (props.lessons.length>0) lessons = props.lessons.map(lesson => <option key={lesson.id} value={lesson.id}>{lesson.value}</option>)

	return <select 
				className="custom-select" 
				id="class_id" 
			>
			{lessons.length>0 ?
			<>
				<option value=''>Selecione uma Aula</option>
				{lessons}
			</> :
				<option value='0' disabled>Nenhum aula cadastrada</option> 
			}
		   	</select>;
}
