import React from 'react';
import { Redirect, Route } from 'react-router-dom';
const axios = require('axios');

export const isAuthenticated = () =>{
	const token = localStorage.getItem('TOKEN_KEY');
	const permission = localStorage.getItem('PERMISSION');
	
	if (token===null || permission==null || (permission!=='2' && permission!=='3')) return false;
	return true;
}

export const getToken = () => localStorage.getItem('TOKEN_KEY');

export const login = async props => {		
	const response = await axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API_URL}/auth/login`,
			data: { 
				email: props.email,
				password: props.password
			}
		})
		.then(response => response.data)
		.catch(error => error )
		
	if (response.token && response.permission.id) {
		localStorage.setItem('PERMISSION', response.permission.id);
		localStorage.setItem('TEACHER_ID', response.permission.teacher_id);
		localStorage.setItem('TOKEN_KEY', response.token);
		return response.permission.id;
	} else { 
		return alert("Login inválido");
	}	
}

export const retrieveUser = async props => {
	if (!props.token) return false;
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/token/${props.token}`)
	.then(response => response.data)
	.catch(() => false)
	return response;
}

export const logout = () => {
  	localStorage.removeItem('PERMISSION');
	localStorage.removeItem('TOKEN_KEY');
	window.location.href="/auth/login";
};


const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={ 
		props => {
			let authenticated = isAuthenticated();
						
			if (rest.permission==='2' && rest.path!=='/teacher-dashboard' && rest.path!=='/teacher-classes' && rest.path!=='/teacher-mentoring') {
				alert("Acesso negado");
				return <Redirect to={{ pathname:'/teacher-dashboard', state:{ from:props.location }}}/>
			}

			if (authenticated) {
				return <Component {...props}/>
			} else {
				return <Redirect to={{ pathname:'/auth/login', state:{ from:props.location }}}/>
			}
		}
	}
	/>); 

export default PrivateRoute
