import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import { remove as removeUser, update as updateUser } from '../../services/User';
import { getAll as getAllTeachers, SelectTeachers } from '../../services/Teacher';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedValues: [],
			openEdit: false,
			open: false,
			rowSelected: '',
			titleRowSelected: '',
			idEdit: '',
			name: '',
			email: '',
			is_admin: false,
			teachers: '', 
			teacherDisabled: true,
			teacher_id: ''
		}
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handlePermissionChange = this.handlePermissionChange.bind(this);
	}
	handleNameChange = event => {
		this.setState({ name: event.target.value });
	}
	handleEmailChange = event => {
		this.setState({ email: event.target.value });
	}
	handlePermissionChange = event => {
		
		const is_admin = event.target.value;
		let teacherDisabled = false;
		
		if (is_admin==='true') {
			teacherDisabled = true;
			document.getElementById("teacher_id").value = ''
		}
		
		this.setState({ 
						is_admin, 
						teacherDisabled 
					});
	}
	_setTeacherID = teacher_id => this.setState({ teacher_id })

	_TeachersGetAll = async () => {
		const teachers = await getAllTeachers();
		this.setState({ teachers });
	}

	onOpenModal = () => this.setState({ open: true });
	onCloseModal = () => this.setState({ open: false });

	onOpenModalEdit = props => {
		this.setState({ 
						openEdit: true, 
						idEdit: props.id, 
						name: props.name, 
						email: props.email, 
						is_admin: props.is_admin, 
						teacher_id: props.teacher_id, 
						teacherDisabled: props.is_admin
					});
	}
	onCloseModalEdit = () => this.setState({ openEdit: false });

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	_removeUser = async props => {
		await removeUser(props);
		this.props.update();
		this.onCloseModal();
		toast.success("Usuário removido com sucesso!");
	}

	_sendForm = e => {
		e.preventDefault();
		this._updateUser();
		return false;
	}

	_updateUser = async () => {
		const password = document.getElementById("password").value, 
			  passwordValidation = document.getElementById("password-validation").value;

		if (password!=='' && password!==passwordValidation) return toast.error("As senha estão diferentes!");
		
		const data = {
				id: this.state.idEdit,
				name: this.state.name,
				email: this.state.email, 
				password: password!=='' ? password : null,
				is_admin: this.state.is_admin==='true' ? true : false, 
				teacher_id: this.state.teacher_id
			}

		const response = await updateUser(data);

		if (response.status) {
			this.props.update();
			toast.success("Usuário editado com sucesso!");
		} else {
			toast.success(data);
		}
		this.props.update();
		this.onCloseModalEdit();
	}

	componentDidMount() { 
		this._TeachersGetAll();
	}

	render() {
		const { pageSize, myClass, pagination, myData } = this.props;
		const columns = [];

		for (var key in myData[0]) {
			columns.push({
				Header: <b>{this.Capitalize(key.toString())}</b>,
				accessor: key,
				Cell: null,
				style: {
					textAlign: 'center',
				},
			});
		}

			columns.push({
				Header: <b>Ações</b>,
				id: 'delete',
				accessor: str => 'delete',
				Cell: row => {
					return (
					<div>
						<span title="Editar usuário" onClick={() => this.onOpenModalEdit({ 
																								id: row.original.id, 
																								name: row.original.name, 
																								email: row.original.email,
																								is_admin: row.original.permission==='3' ? true : false,
																								teacher_id: row.original.teacher_id
																							})}>
							<i
								className="fa fa-pencil default-color"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
								}}
							></i>
						</span>
						<span onClick={() => {
							this.setState({ rowSelected: row.original.id });
							this.setState({ titleRowSelected: row.original.Nome });
							this.onOpenModal();
						}}
						title="Deletar">
							<i
								className="fa fa-trash"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
									cursor: 'pointer'
								}}
							></i>
						</span>
					</div>
				)},
				style: {
					textAlign: 'center',
				},
				sortable: false,
			});

		return (
			<Fragment>
				<ReactTable
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
					previousText="Voltar"
					nextText="Próximo"
					loadingText="Carregando..."
					noDataText="Sem dados"
					pageText="Página"
					ofText="de"
					rowsText="Linhas"
				/>
				<Modal open={this.state.open} onClose={this.onCloseModal}>
					<div className="modal-header">
						<h5
							className="modal-title f-w-600"
							id="exampleModalLabel2"
						>
							Atenção
						</h5>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Você realmente deseja remover o usuário <strong>{this.state.titleRowSelected}</strong> do sistema?
							</label>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModal()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this.state.rowSelected!=='' ? 
											this._removeUser({ id: this.state.rowSelected }) :
											toast.success("Erro ao deletar o Lead.") }
						>
							Confirmar
						</button>
					</div>
				</Modal>
				<Modal open={this.state.openEdit} onClose={this.onCloseModalEdit}>
					<div className="modal-header">
						<h5 className="modal-title f-w-600">
							Editar Usuário
						</h5>
					</div>
					<form onSubmit={(e) => this._sendForm(e) }>
						<div className="modal-body">
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Nome</label>
										<input 
											type="text" 
											className="form-control" 
											id="name" 
											placeholder="Nome do usuário" 
											value={this.state.name} 
											onChange={this.handleNameChange}
										/>
									</div>

								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Login</label>
										<input 
											type="email" 
											className="form-control" 
											id="login" 
											placeholder="Login do usuário"  
											autocomplete="username"
											value={this.state.email} 
											onChange={this.handleEmailChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Alterar Senha</label>
										<input type="password" className="form-control" autoComplete="new-password" id="password" placeholder="Senha do usuário" />
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Confirme a Senha nova</label>
										<input type="password" className="form-control" autoComplete="new-password" id="password-validation" placeholder="Digite a senha novamente" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label>Nível de Acesso</label>
											<select 
												className="form-control" 
												id="is_admin"
												onChange={this.handlePermissionChange} 
												value={this.state.is_admin}
											>
												<option value={true}>Admin</option>
												<option value={false}>Professor</option>
											</select>
									</div>
								</div>
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label for="exampleFormControlSelect1">Professor</label>
										<SelectTeachers id={ this.state.teacher_id } teachers={this.state.teachers} disabled={ this.state.teacherDisabled } update={this._setTeacherID} />
									</div>
								</div>
							</div>
						</div>
					</form>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.onCloseModalEdit()}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => this._updateUser() }
						>
							Salvar
						</button>
					</div>
				</Modal>
				<ToastContainer />
			</Fragment>
		);
	}
}

export default Datatable;
