export function ptToEng(day){
	switch (day) {
		case '1':
			return 'monday'
		case '2':
			return 'tuesday'
		case '3':
			return 'wednesday'
		case '4':
			return 'thursday'
		case '5':
			return 'friday'
		default:
			return ''
	}
}

export function engToPT(day) {
	switch (day) {
		case 'monday':
			return 'Segunda'
		case 'tuesday':
			return 'Terça'
		case 'wednesday':
			return 'Quarta'
		case 'thursday':
			return 'Quinta'
		case 'friday':
			return 'Sexta'
		default:
			return ''
	}
}

export function dayID(day){
	switch (day) {
		case 'monday':
			return 1
		case 'tuesday':
			return 2
		case 'wednesday':
			return 3
		case 'thursday':
			return 4
		case 'friday':
			return 5
		default:
			return ''
	}
}
