import React, { Component, Fragment } from "react";
import LoginTabset from "./loginTabset";
import logo from "../../assets/images/dashboard/logo.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

export class Login extends Component {
  render() {
    return (
      <Fragment>
        <div className="page-wrapper">
          <div className="authentication-box">
            <div className="container">
              <div className="row login-container">
                <img className="logo-image" src={logo} alt="" />
                <div className="col-md-6 p-0">
                  <div className="card tab2-card">
                    <div className="card-body">
                      <LoginTabset />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
