const axios = require('axios');

export const retrieveApi = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/bill/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const retrieveApiBills = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/bill/subscription/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const update = async props => {
	const response = await axios.put( process.env.REACT_APP_API_URL + '/bill/' + props.id, props.data)
		.then(response => response.data)
		.catch(error => error )
		return response;
}
