import React, { Component, Fragment } from 'react';
import UserPanel from './user-panel';
import { Link } from 'react-router-dom';
import { MENUITEMSADMIN, MENUITEMSTEACHER } from '../../../constants/menu';
import { logout } from '../../../services/Auth';
import { LogIn } from 'react-feather';
// image import
import logo from '../../../assets/images/dashboard/logoSM.svg';
let MENUITEMS = MENUITEMSTEACHER;

export class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			selectedPath: '1', 
			mainmenu: [], 
			menuLoaded: false
		};
	}
	onItemSelection = (arg, e) => {
		this.setState({ selectedPath: arg.path });
	};

	_getMainMenu = () => {
		const permission = localStorage.getItem('PERMISSION');

		if (permission==='3') {
			MENUITEMS = MENUITEMSADMIN;
			this.setState({ mainmenu: MENUITEMSADMIN }, () => this._loadMenu());
		} else if (permission==='2') {
			MENUITEMS = MENUITEMSTEACHER;
			this.setState({ mainmenu: MENUITEMSTEACHER }, () => this._loadMenu());
		}
	}

	_loadMenu = () => {		
		var currentUrl = window.location.pathname;

		this.state.mainmenu.filter(items => {
			if (!items.children) {
				if (items.path === currentUrl) this.setNavActive(items);
				return false;
			}
			items.children.filter(subItems => {
				if (subItems.path === currentUrl) this.setNavActive(subItems);
				if (!subItems.children) return false;
				subItems.children.filter(subSubItems => {
					if (subSubItems.path === currentUrl)
						this.setNavActive(subSubItems);
						return false;
				});
				return false;
			});
			return false;
		});

	}

	setNavActive(item) {
		MENUITEMS.filter(menuItem => {
			if (menuItem !== item) menuItem.active = false;
			if (menuItem.children && menuItem.children.includes(item))
				menuItem.active = true;
			if (menuItem.children) {
				menuItem.children.filter(submenuItems => {
					if (submenuItems !== item) {
						submenuItems.active = false;
					}
					if (submenuItems.children) {
						submenuItems.children.map(childItem => {
							return childItem.active = false;
						});
						if (submenuItems.children.includes(item)) {
							submenuItems.active = true;
							menuItem.active = true;
						}
					}
					return false;
				});
			}
			return false;
		});
		item.active = !item.active;

		this.setState({
			mainmenu: MENUITEMS,
			menuLoaded: true
		});
	}

	componentDidMount() {
		this._getMainMenu();		
	}
	componentDidUpdate() {
		if (this.state.mainmenu.length===0) this._getMainMenu();
		else if (!this.state.menuLoaded) this._loadMenu()
	}

	render() {
		const mainmenu = this.state.mainmenu.map((menuItem, i) => (
			<li className={`${menuItem.active ? 'active' : ''}`} key={i}>
				{menuItem.sidebartitle && (
					<div className="sidebar-title">{menuItem.sidebartitle}</div>
				)}
				{menuItem.type === 'sub' && (
					<button
						className="sidebar-header "
						onClick={() => this.setNavActive(menuItem)}
					>
						<menuItem.icon />
						<span>{menuItem.title}</span>
						<i className="fa fa-angle-right pull-right"></i>
					</button>
				)}
				{menuItem.type === 'link' ? (
					<Link
						to={`${menuItem.path}`}
						className={`sidebar-header ${
							menuItem.active ? 'active' : ''
						}`}
						onClick={() => this.setNavActive(menuItem)}
					>
						<menuItem.icon />
						<span>{menuItem.title}</span>
						{menuItem.children ? (
							<i className="fa fa-angle-right pull-right"></i>
						) : (
							''
						)}
					</Link>
				) : (
					''
				)}
				{menuItem.children ? (
					<ul
						className={`sidebar-submenu ${
							menuItem.active ? 'menu-open' : ''
						}`}
						style={
							menuItem.active
								? {
										opacity: 1,
										transition: 'opacity 500ms ease-in',
								  }
								: {}
						}
					>
						{menuItem.children.map((childrenItem, index) => (
							<li
								key={index}
								className={
									childrenItem.children
										? childrenItem.active
											? 'active'
											: ''
										: ''
								}
							>
								{childrenItem.type === 'sub' ? (
									<button
										className="sidebar-header "
										onClick={() =>
											this.setNavActive(childrenItem)
										}
									>
										{childrenItem.title}{' '}
										<i className="fa fa-angle-right pull-right"></i>
									</button>
								) : (
									''
								)}

								{childrenItem.type === 'link' ? (
									<Link
										to={`${childrenItem.path}`}
										className={
											childrenItem.active ? 'active' : ''
										}
										onClick={() =>
											this.setNavActive(childrenItem)
										}
									>
										{childrenItem.icon && <i className={childrenItem.icon}></i>}
										{childrenItem.title}{' '}
									</Link>
								) : (
									''
								)}
								{childrenItem.children ? (
									<ul
										className={`sidebar-submenu ${
											childrenItem.active
												? 'menu-open'
												: 'active'
										}`}
									>
										{childrenItem.children.map(
											(childrenSubItem, key) => (
												<li
													className={
														childrenSubItem.active
															? 'active'
															: ''
													}
													key={key}
												>
													{childrenSubItem.type ===
													'link' ? (
														<Link
															to={`${childrenSubItem.path}`}
															className={
																childrenSubItem.active
																	? 'active'
																	: ''
															}
															onClick={() =>
																this.setNavActive(
																	childrenSubItem,
																)
															}
														>
															{childrenSubItem.icon && <i className={childrenSubItem.icon}></i>}
															{
																childrenSubItem.title
															}
														</Link>
													) : (
														''
													)}
												</li>
											),
										)}
									</ul>
								) : (
									''
								)}
							</li>
						))}
					</ul>
				) : (
					''
				)}
			</li>
		));

		return (
			<Fragment>
				<div className="page-sidebar">
					<div className="main-header-left d-none d-lg-block">
						<div className="logo-wrapper">
							<Link to={`/dashboard`}>
								<img
									className="blur-up lazyloaded"
									src={logo}
									alt=""
								/>
							</Link>
						</div>
					</div>
					<div className="sidebar custom-scrollbar">
						<UserPanel />
						<ul className="sidebar-menu">
							{mainmenu}
							<li>
								<a className="sidebar-header " href="#sair" onClick={logout}>
									<LogIn />
									<span>Sair</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default SideBar;
