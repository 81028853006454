import React from 'react';
import { engToPT } from '../utils/convertDay';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/mentoring-list' )
	.then(response => response.data)
	.catch(error => { return { error: error} })
	return response;
}

export const getClassesFormated = async props => {
	let classesFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		classesFormated = props.data.map((classInfo) => {

			if (classInfo.deleted_at || classInfo.active===0) className='text-danger'
			else className='text-success';			
			return { 
				id: 	classInfo.id,
				title:	classInfo.title,
				thumb: 	classInfo.thumbnail, 
				Nome: 		<span className={className}>{classInfo.title}</span>,
				Professor: 	<span className={className}>{classInfo.teacher}</span>,
				'Dia da Semana':<span className={className}>{engToPT(classInfo.week)}</span>, 
			}
		});
	}
	return classesFormated;
}

export const save = async props => {	
	const daySave = moment().format("YYYY-MM-DD");
	const response = await axios.post( process.env.REACT_APP_API_URL + '/class', { 
																					name: props.name,
																					lesson_id: props.lesson_id, 
																					teacher_id: props.teacher_id, 
																					week: props.week, 
																					is_mentoring: 1,
																					date: daySave, 
																					start_at: props.start_at, 
																					finish_at: props.finish_at, 
																					thumbnail: props.thumbnail,
																					thumb_type: props.thumb_type,
																					file_name: props.file_name,
																					url: props.url,
																					number: props.number, 
																					password: props.password
																				})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const update = async props => {
	const response = await axios.put( process.env.REACT_APP_API_URL + '/class/'+props.id, { 
																					name: props.name,
																					lesson_id: props.lesson_id, 
																					teacher_id: props.teacher_id, 
																					transmition_id: props.transmition_id, 
																					date: props.date,
																					week: props.week,  
																					start_at: props.start_at, 
																					finish_at: props.finish_at,
																					thumbnail: props.thumbnail,
																					thumb_type: props.thumb_type,
																					file_name: props.file_name 
																				})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const updateStatus = async props => {
	const response = await axios.put( process.env.REACT_APP_API_URL + '/mentoring', {
																						teacher_id: props.id, 
																						status: props.status
																					})
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const retrieve = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/class/show/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const remove = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/class/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const SelectDay = props => {
	let days = [];
	if (props.days.length > 0) days = props.days.map(day => <option key={day.id} value={day.id}>{day.name}</option>)

	return <select
		className="form-control"
		id="day_id"
		defaultValue={props.id}
		onChange={() => {
			if (props.update) props.update(document.getElementById("teacher_id").value)
		}}
	>
		{days.length > 0 ?
			<>
				<option value=''>Dia da Semana</option>
				{days}
			</> :
			<option value='0' disabled>Nenhum dia da semana cadastrado</option>
		}
	</select>;
}


export const SelectLessons = props => {
	let lessons = [];
	if (props.lessons.length>0) lessons = props.lessons.map(lesson => <option key={lesson.id} value={lesson.id}>{lesson.name}</option>)

	return <select 
				className="form-control" 
				id="lesson_id" 
				defaultValue={props.id}
				onChange={() => {
					if (props.update) props.update(document.getElementById("lesson_id").value) 
				}}
			>
				{lessons.length>0 ?
				<>
					<option value=''>Selecione uma disciplina</option>
					{lessons}
				</> :
					<option value='0' disabled>Nenhuma disciplina cadastrada</option> 
				}
		   	</select>;
}

export const SelectTeachers = props => {
	let teachers = [];
	if (props.teachers.length>0) teachers = props.teachers.map(teacher => <option key={teacher.id} value={teacher.id}>{teacher.name}</option>)

	return <select 
				className="form-control" 
				id="teacher_id" 
				defaultValue={props.id}
				onChange={() => {
					if (props.update) props.update(document.getElementById("teacher_id").value) 
				}}
			>
				{teachers.length>0 ?
				<>
					<option value=''>Selecione um professor</option>
					{teachers}
				</> :
					<option value='0' disabled>Nenhum professor cadastrado</option> 
				}
		   	</select>;
}
