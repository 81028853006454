import React from 'react';
import { getToken } from './Auth';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
	const token = getToken();	
	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};
		const response = await axios.get( process.env.REACT_APP_API_URL + '/question/list', config )
			.then(response => response.data)
			.catch(error => { return { error: error} })
			return response;
	} else {
		return { error: 'Erro ao buscar as perguntas' }
	}
}

export const getQuestionFormated = async props => {
	let questionFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		questionFormated = props.data.map(question => {
			if (question.deleted_at) className='text-danger'
			else className='text-success';
			return { 
				id: question.id,
				question: question.question, 
				first_answer: question.first_answer, 
				second_answer: question.second_answer, 
				third_answer: question.third_answer, 
				Pergunta: 		<span className={className}>{question.question}</span>,
				Cadastrado:	<span className={className}>
								{moment(question.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")}
							</span>, 
				Editado: 	<span className={className}>
								{moment(question.updated_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")}
							</span>
			}
		});
	}
	return questionFormated;
}

export const save = async ({ question, firstAnswer, secondAnswer, thirdAnswer }) => {
	const token = getToken();	
	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
		const data = {
			question: question, 
			first_answer: firstAnswer, 
			second_answer: secondAnswer, 
			third_answer: thirdAnswer 
		}

		const response = await axios.post( process.env.REACT_APP_API_URL + '/question', data)
									.then(response => response.data)
									.catch(error => error )
		return response;
	} else {
		return { error: 'Erro ao buscar as perguntas' }
	}
}

export const retrieve = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/class/show/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}

export const update = async props => {
	const token = getToken();	
	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
		const data = {
			question: props.question, 
			first_answer: props.first_answer, 
			second_answer: props.second_answer, 
			third_answer: props.third_answer 
		}		
		const response = await axios.put( process.env.REACT_APP_API_URL + '/question/' + props.id, data)
									.then(response => response.data)
									.catch(error => error )
		return response;
	} else {
		return { error: 'Erro ao editar a pergunta' }
	}
}

export const remove = async props => {
	const token = getToken();	
	if (token!=='') {
		axios.defaults.headers.common['Authorization'] = token;
		const response = await axios.delete( process.env.REACT_APP_API_URL + '/question/' + props.id )
			.then(response => response.data)
			.catch(error => error )
			return response;
	} else {
		return { error: 'Erro ao buscar as perguntas' }
	}
}
