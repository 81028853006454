import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import ClassTable from './class-table';
import { getTeacherClasses as getAll, getTeacherClassesFormated as getClassesFormated } from '../../services/Classes';

export class Classes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allClasses: '',
		};
		this.fileInput = React.createRef();
	}

	_ClassesGetAll = async () => {
		const teacher_id = this.state.teacher_id,
			allData = await getAll({ id: teacher_id });

		if (allData.error) {
			this.setState({
				allClasses: [
					{
						id: '0',
						title: '',
						thumb: '',
						Nome: (
							<span className="text-danger">
								<i className="fa fa-meh-o mr-2"></i>
								Nenhuma Aula encontrada
							</span>
						),
						Professor: '',
						Data: '',
					},
				],
			});
		} else {
			const allClasses = await getClassesFormated({ data: allData });
			this.setState({ allClasses });
		}
	};

	_getTeacherId = () => {
		const teacher_id = localStorage.getItem('TEACHER_ID');
		this.setState({ teacher_id }, () => this._ClassesGetAll());
	};

	componentDidMount() {
		this._getTeacherId();
	}

	render() {
		return (
			<div>
				<Breadcrumb title="Aulas" backButton={false} />
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-body">
									<div
										id="basicScenario"
										className="report-table"
									>
										<ClassTable
											allClasses={this.state.allClasses}
											update={this._ClassesGetAll}
											lessons={this.state.lessons}
											teachers={this.state.teachers}
											transmitions={
												this.state.transmitions
											}
										/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>			
            </div>
        )
    }
}

export default Classes;
