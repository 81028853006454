import React from 'react';
const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {	
const response = await axios.get( process.env.REACT_APP_API_URL + '/teacher' )
	.then(response => response.data)
	.catch(error => { return { error: error} })
	return response;
}

export const getTeacherFormated = async props => {
	let teacherFormated = [],
		className='text-muted';
	if (props.data.length>0) {
		teacherFormated = props.data.map((teacher) => {
			if (teacher.deleted_at || teacher.active===0) className='text-danger'
			else className='text-success'

			return { 
				id: teacher.id,
				name: 		teacher.name,
				thumb: 		teacher.thumbnail,
				Nome: 		<span className={className}>{teacher.name}</span>,
				Cadastrado:	<span className={className}>
								{moment(teacher.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>, 
				Editado: 	<span className={className}>
								{moment(teacher.updated_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm")}
							</span>
			}
		});
	}
	return teacherFormated;
}

export const save = async props => {
	const response = await axios.post( process.env.REACT_APP_API_URL + '/teacher', { 
																						name: props.name,
																						thumbnail: props.thumbnail!=='' ? props.thumbnail : null,
																						thumb_type: props.thumb_type!=='' ? props.thumb_type : null,
																						file_name: props.file_name!=='' ? props.file_name : null 
																					})
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const retrieve = async props => {
	const response = await axios.get( process.env.REACT_APP_API_URL + '/teacher/' + props.id)
								.then(response => response.data)
								.catch(error => error )
	return response;
}

export const update = async props => {
	let data = { 
		id:props.id, 
		name: props.name
	}
	
	if (props.thumbnail!=='' && props.thumb_type!=='') {
		data = {
			...data,
			thumbnail: props.thumbnail,
			thumb_type: props.thumb_type,
			file_name: props.thumb_name
		}
	}

	const response = await axios.put( process.env.REACT_APP_API_URL + '/teacher', data)
								.then(response => response.data)
								.catch(error => error );
	return response;
}

export const remove = async props => {
	try {
		const response = await axios.delete( process.env.REACT_APP_API_URL + '/teacher/' + props.id )
			.then(response => response.data)
			.catch(error => error )
			return response;
		}
		catch (e) {
		   return { errorObject: e };
		}
}

export const SelectTeachers = props => {
	let teachers = [];
	if (props.teachers.length>0) teachers = props.teachers.map(teacher => <option key={teacher.id} value={teacher.id}>{teacher.name}</option>)
	let disabled = false;
	if (props.disabled!==undefined) {
		disabled = props.disabled;
	} 
	
	return <select 
				className="form-control" 
				id="teacher_id" 
				defaultValue={props.id}
				disabled={disabled}
				onChange={() => {
					if (props.update) props.update(document.getElementById("teacher_id").value) 
				}}
			>	
				{teachers.length>0 ?
				<>
					<option value=''>Selecione um professor</option>
					{teachers}
				</> :
					<option value=''>Nenhum professor cadastrado</option> 
				}
		   	</select>;
}

