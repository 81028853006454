const axios = require('axios');
var moment = require('moment');

export const getAll = async () => {
const response = await axios.get( process.env.REACT_APP_API_URL + '/newsletter' )
	.then(response => response.data)
	.catch(error => error )
	return response;
}

export const getNewsFormated = async props => {
	let newsFormated = [];
	if (props.data.length>0) {
		newsFormated = props.data.map((lead) => {
			return { 
				id: lead.id,
				Email: lead.email,
				Cadastrado: moment(lead.created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm:ss")
			}
		});
	}
	return newsFormated;
}

export const remove = async props => {
	const response = await axios.delete( process.env.REACT_APP_API_URL + '/newsletter/' + props.id )
		.then(response => response.data)
		.catch(error => error )
		return response;
}
