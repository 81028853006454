import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';
import PrivateRoute from './services/Auth';

// Components
import Dashboard from './components/dashboard';
import Login from './components/auth/login';
import Surveys from './components/surveys/survey';
import Newsletter from './components/newsletters/newsletter';
import Subscriptions from './components/subscriptions/subscription';
import DetailsSubscription from './components/subscriptions/details';
import Lessons from './components/lessons/lesson';
import Teachers from './components/teachers/teachers';
import Class from './components/class/classes';
import Mentoring from './components/mentoring/mentoring';
import Questions from './components/questions/question';
// import Products from './components/sales/products/products';
import Contents from './components/contents/content';
// import Books from './components/books/book';
import Users from './components/users/user';
import Students from './components/students/students';

import TeacherDashboard from './components/teacher-dashboard';
import TeacherClass from './components/teacher-classes/teacher-classes';

class Root extends Component {
	_getPermission = () => localStorage.getItem('PERMISSION');
	render() {
		const permission = this._getPermission();
		return (
			<BrowserRouter basename={'/'}>
				<ScrollContext>
					<Switch>
						<Route exact path={`/`} component={Login} />
						<Route exact path={`/auth/login`} component={Login} />

						<App>
							<PrivateRoute permission={permission} path={`/dashboard`} component={Dashboard} />
							
							<PrivateRoute permission={permission} path={`/surveys`} component={Surveys} />
							<PrivateRoute permission={permission} path={`/newsletters`} component={Newsletter} />
							<PrivateRoute permission={permission} path={`/subscriptions`} component={Subscriptions} />
							<PrivateRoute permission={permission} path={`/subscription/:id`} component={DetailsSubscription} />
							<PrivateRoute permission={permission} path={`/lessons`} component={Lessons} />
							<PrivateRoute permission={permission} path={`/teachers`} component={Teachers} />
							<PrivateRoute permission={permission} path={`/classes`} component={Class} />
							<PrivateRoute permission={permission} path={`/mentoring`} component={Mentoring} />
							<PrivateRoute permission={permission} path={`/questions`} component={Questions} />
							<PrivateRoute permission={permission} path={`/contents`} component={Contents} />
							{/* <PrivateRoute path={`/books`} component={Books} /> */}
							<PrivateRoute permission={permission} path={`/users`} component={Users} />
							<PrivateRoute permission={permission} path={`/students`} component={Students} />

							<PrivateRoute permission={permission} path={`/teacher-dashboard`} component={TeacherDashboard} />
							<PrivateRoute permission={permission} path={`/teacher-classes`} component={TeacherClass} />

							{/* <PrivateRoute path={`/sales/products`} component={Products} /> */}
							{/* <PrivateRoute path={`/sales/plans`} component={Plans} /> */}
						</App>
					</Switch>
				</ScrollContext>
			</BrowserRouter>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
